<ngx-spinner
  [fullScreen]="true"
  bdColor="#fff"
  size="medium"
  color="#050a4f"
  type="ball-clip-rotate-multiple"
>
</ngx-spinner>
<img
  *ngIf="lapshow"
  class="background-img"
  src="../../../assets/images/login-bg.jpg"
/>
<img
  *ngIf="mobshow"
  class="background-img"
  src="../../../assets/images/mobile.jpg"
/>
<!-- <div class="footer">
  <a>Designed & Developed By Info Onclick LLC</a>
</div> -->
<div class="row">
  <div class="footer col-md-9 col-sm-2">
    <a>Designed & Developed By Info Onclick LLC</a>
  </div>
  <div class="col-sm-10 col-md-3 card-wrapper">
    <h3 class="call-to-action">Admin Login</h3>

    <form
      class="w-75 d-flex align-items-center"
      [formGroup]="loginForm"
      (ngSubmit)="onSubmit(loginForm, $event)"
    >
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Email</mat-label>
        <input
          matInput
          type="email"
          formControlName="email"
          placeholder="Enter email"
        />
        <mat-error> Enter Valid Email </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Password</mat-label>
        <input
          matInput
          [type]="showPassword ? 'text' : 'password'"
          formControlName="password"
          placeholder="Enter password"
        />
        <mat-icon
          *ngIf="showPassword"
          matSuffix
          (click)="togglePasswordVisibility()"
        >
          <img
            style="height: 15px; width: 15px; margin-bottom: 15px"
            src="../../../assets/images/view.png"
            alt=""
          />
        </mat-icon>
        <mat-icon
          *ngIf="!showPassword"
          matSuffix
          (click)="togglePasswordVisibility()"
        >
          <img
            style="height: 15px; width: 15px; margin-bottom: 15px"
            src="../../../assets/images/eye.png"
            alt=""
          />
        </mat-icon>
        <mat-error> Enter Password </mat-error>
      </mat-form-field>
      <button
        style="
          background-color: #3b8884;
          border: none;
          padding: 15px;
          font-weight: bold;
          color: white;
        "
        class="w-100"
        type="submit"
      >
        Login
      </button>
      <a
        routerLink="/auth/forgot-password"
        style="color: rgb(54, 49, 49)"
        class="text mt-3"
        >Forgot password?</a
      >
      <a
        class="text mt-3"
        style="text-align: center; color: rgb(54, 49, 49)"
        (click)="termsAndConditions()"
        >By clicking login button you were accepting our Terms & Conditions</a
      >
    </form>
  </div>
</div>

<ng-template #callAPIDialog>
  <section>
    <div class="container py-5">
      <!-- FOR DEMO PURPOSE -->
      <header class="text-center mb-5 text-white">
        <div class="row">
          <div class="col-lg-7 mx-auto">
            <h1 style="color: #3a7976">Purchase Plan</h1>
            <!-- <p>
                  Easily create a classy pricing table in Bootstrap&nbsp;4.<br />
                  <a href="https://bootstrapious.com/snippets" class="text-reset"
                    >Bootstrap snippet by Bootstrapious</a
                  >
                </p> -->
          </div>
        </div>
      </header>
      <!-- END -->

      <div class="row text-center align-items-end">
        <!-- Pricing Table-->

        <ng-container *ngFor="let item of planDetails">
          <div class="col-lg-4 mb-5 mb-lg-0">
            <div class="bg-white p-5 rounded-lg shadow">
              <h1 class="h5 text-uppercase font-weight-bold mb-4">
                {{ item?.name }}
              </h1>
              <h1 class="h6 text-uppercase font-weight-bold mb-4">
                No of Posting {{ item?.posting }}
              </h1>
              <h2 class="h1 font-weight-bold" style="font-size: 2rem">
                Rs.{{ item?.monthly_charge
                }}<span class="text-small font-weight-bold ml-2"
                  >/ Monthly</span
                >
              </h2>
              <h2 class="h1 font-weight-bold" style="font-size: 2rem">
                Rs.{{ item?.quarterly_charge
                }}<span class="text-small font-weight-bold ml-2"
                  >/ Quaterly</span
                >
              </h2>

              <div class="custom-separator my-4 mx-auto bg-success"></div>

              <!-- <ul class="list-unstyled my-5 text-small text-left">
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i> Monthly Offer
                  Limited edition only
                </li>
                <li class="mb-3">
                  <i class="fa fa-check mr-2 text-primary"></i> Quaterly Offer
                  Limited edition only
                </li>
                <li class="mb-3 text-muted">
                  <i class="fa fa-times mr-2"></i>
                  <del>There is no hidden charges are taken</del>
                </li>
              </ul> -->
              <a
                class="btn btn-block p-2 shadow rounded-pill"
                *ngIf="planDetails[0]?.monthly_charge == item?.monthly_charge"
                href="https://pages.razorpay.com/pl_LLrgn1z0jG7dlW/view"
                class="btn btn-block p-2 shadow rounded-pill"
                >Buy Monthly {{ item?.monthly_charge | currency : "INR" }}</a
              >
              <a
                class="btn btn-block p-2 shadow rounded-pill"
                *ngIf="
                  planDetails[0]?.quarterly_charge == item?.quarterly_charge
                "
                href="https://pages.razorpay.com/pl_LLrmFqF3haaBah/view"
                class="btn btn-block p-2 shadow rounded-pill"
                >Buy Quarterly
                {{ item?.quarterly_charge | currency : "INR" }}</a
              >

              <a
                class="btn btn-block p-2 shadow rounded-pill"
                *ngIf="planDetails[1]?.monthly_charge == item?.monthly_charge"
                href="https://pages.razorpay.com/pl_LLrpZZDD0MZhmt/view"
                class="btn btn-block p-2 shadow rounded-pill"
                >Buy Monthly {{ item?.monthly_charge | currency : "INR" }}</a
              >
              <a
                class="btn btn-block p-2 shadow rounded-pill"
                *ngIf="
                  planDetails[1]?.quarterly_charge == item?.quarterly_charge
                "
                href="https://pages.razorpay.com/pl_LLrvQj41ZqiMvD/view"
                class="btn btn-block p-2 shadow rounded-pill"
                >Buy Quarterly
                {{ item?.quarterly_charge | currency : "INR" }}</a
              >

              <a
                class="btn btn-block p-2 shadow rounded-pill"
                *ngIf="planDetails[2]?.monthly_charge == item?.monthly_charge"
                href="https://pages.razorpay.com/pl_LLryVclZobSoud/view"
                class="btn btn-block p-2 shadow rounded-pill"
                >Buy Monthly {{ item?.monthly_charge | currency : "INR" }}</a
              >
              <a
                class="btn btn-block p-2 shadow rounded-pill"
                *ngIf="
                  planDetails[2]?.quarterly_charge == item?.quarterly_charge
                "
                href="https://pages.razorpay.com/pl_LLs25csDKgxGYD/view"
                class="btn btn-block p-2 shadow rounded-pill"
                >Buy Quarterly
                {{ item?.quarterly_charge | currency : "INR" }}</a
              >
              <!-- <button
                class="btn btn-primary btn-block p-2 shadow rounded-pill"
                (click)="purchasePlan(item, 1)"
              >
                Buy Monthly {{ item?.monthly_charge | currency : "INR" }}
              </button>

              <button
                class="btn btn-primary btn-block p-2 shadow rounded-pill"
                (click)="purchasePlan(item, 2)"
              >
                Buy Quarterly {{ item?.quarterly_charge | currency : "INR" }}
              </button> -->
            </div>
          </div>
        </ng-container>

        <!-- END -->

        <!-- Pricing Table-->
        <!-- <div class="col-lg-4 mb-5 mb-lg-0">
              <div class="bg-white p-5 rounded-lg shadow">
                <h1 class="h6 text-uppercase font-weight-bold mb-4">Pro</h1>
                <h2 class="h1 font-weight-bold">
                  $399<span class="text-small font-weight-normal ml-2">/ month</span>
                </h2>
      
                <div class="custom-separator my-4 mx-auto bg-primary"></div>
      
                <ul
                  class="list-unstyled my-5 text-small text-left font-weight-normal"
                >
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Lorem ipsum dolor
                    sit amet
                  </li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis
                  </li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> At vero eos et
                    accusamus
                  </li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Nam libero tempore
                  </li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis
                  </li>
                  <li class="mb-3 text-muted">
                    <i class="fa fa-times mr-2"></i>
                    <del>Sed ut perspiciatis</del>
                  </li>
                </ul>
                <a href="#" class="btn btn-primary btn-block p-2 shadow rounded-pill"
                  >Subscribe</a
                >
              </div>
            </div> -->
        <!-- END -->

        <!-- Pricing Table-->
        <!-- <div class="col-lg-4">
              <div class="bg-white p-5 rounded-lg shadow">
                <h1 class="h6 text-uppercase font-weight-bold mb-4">Enterprise</h1>
                <h2 class="h1 font-weight-bold">
                  $899<span class="text-small font-weight-normal ml-2">/ month</span>
                </h2>
      
                <div class="custom-separator my-4 mx-auto bg-primary"></div>
      
                <ul
                  class="list-unstyled my-5 text-small text-left font-weight-normal"
                >
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Lorem ipsum dolor
                    sit amet
                  </li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis
                  </li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> At vero eos et
                    accusamus
                  </li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Nam libero tempore
                  </li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis
                  </li>
                  <li class="mb-3">
                    <i class="fa fa-check mr-2 text-primary"></i> Sed ut perspiciatis
                  </li>
                </ul>
                <a href="#" class="btn btn-primary btn-block p-2 shadow rounded-pill"
                  >Subscribe</a
                >
              </div>
            </div> -->
        <!-- END -->
      </div>
    </div>
  </section>
</ng-template>

<ng-template #callAPIDialog1>
  <section>
    <div class="container py-5">
      <h3 style="text-align: center; margin-bottom: 6px">
        Terms & Conditions for using this website
      </h3>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <ol>
            <li>
              1. All rights reserved to Info Onclick LLC(OwnerShip Company),
              it's has complete rights to cancel / deny the stores with /
              without any reasons.
            </li>
            <li>
              2. By using this website, every user and store owner are accepting
              our terms & conditions and privacy policy etc..
            </li>
            <li>
              3. Store Owner should be reviewing their product and it's prices
              on a daily basis to make sure it's accuracy, they should honour
              the orders at the prices what was agreed at the time of ordering
            </li>
            <li>
              4. Store Owner / Admin should renew their plans on a monhly or
              quaterly basis in few days of advance before renewal date, failing
              which there might be additional penalty charges might occur.
            </li>
            <li>
              5. Your Store information & personal contact details will be
              shared with other users or vendors to get you best possible deals
              or services.
            </li>
          </ol>
        </div>
      </div>
    </div>
  </section>
</ng-template>
