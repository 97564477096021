
<ngx-spinner [fullScreen]="true" bdColor="#fff" size="medium" color="#050a4f" type="ball-clip-rotate-multiple">
</ngx-spinner>
<div class="container-fluid bg-white">
    <form name="userForm" [formGroup]="userForm" (ngSubmit)="onSubmit()">
        <h2>User </h2>
        <div class="position-relative">
            <div class="row">
                <div class="col-sm-12 col-md-4">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Name </mat-label>
                        <input matInput [(ngModel)]="user.name" required formControlName="name">
                        <mat-error> Name is Required </mat-error>
                    </mat-form-field>
                </div>
                
                <div class="col-sm-12 col-md-4">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Email </mat-label>
                        <input [readonly]="user?.id" matInput type="email" [(ngModel)]="user.email" required formControlName="email">
                        <mat-error> Email is Required </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4">
                    <ngx-intl-tel-input class="form-control h-52px mt-1 d-flex align-items-center" matInput [cssClass]="'phoneNumberClass'"
                    [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true" [searchCountryFlag]="true"
                    [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]" [selectFirstCountry]="false"
                    [selectedCountryISO]="CountryISO.India" [maxLength]="10" [phoneValidation]="true"
                    [separateDialCode]="false" [numberFormat]="PhoneNumberFormat.National" name="phoneno" [ngModelOptions]="{standalone: true}" [(ngModel)]="phoneobj">
                    </ngx-intl-tel-input>     
                    <p class="text-danger" *ngIf="userForm?.controls?.phone?.touched && userForm?.controls?.phone?.errors">Enter a valid phone number</p>   
                
                </div>
                

            </div>
            <div class="row">
                <div class="col-sm-12 col-md-4">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Role </mat-label>
                        <mat-select  [(ngModel)]="user.role" formControlName="role">
                            <mat-option value=""> Select Role </mat-option>
                            <mat-option *ngFor="let role of roleList" [value]="role.id"> {{role.title}} </mat-option>
                        </mat-select>
                        <mat-error> Role is Required </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>State</mat-label>
                    <mat-select
                    [(ngModel)]="user.state_id"
                      (selectionChange)="
                        userForm?.value?.state_id &&
                          getCityList(userForm?.value?.state_id)
                      "
                      required
                      formControlName="state_id"
                    >
                      <input
                        (keyup)="search($event.target.value, 'state')"
                        class="form-control w-100"
                        placeholder="Search State"
                      />
                      <mat-option>Select State</mat-option>
                      <mat-option
                        *ngFor="let state of filteredStateList"
                        [value]="state.id"
                      >
                        {{ state.name + " (" + state.code + ")" }}
                      </mat-option>
                    </mat-select>
                    <mat-error> State is Required </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4">
                  <mat-form-field appearance="outline" class="w-100">
                    <mat-label>City</mat-label>
                    <mat-select [(ngModel)]="user.city_id" required formControlName="city_id">
                      <input
                        (keyup)="search($event.target.value, 'city')"
                        class="form-control w-100"
                        placeholder="Search City"
                      />
                      <mat-option>Select City</mat-option>
                      <mat-option
                        *ngFor="let city of filteredCityList"
                        [value]="city.id"
                      >
                        {{ city.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error> City is Required </mat-error>
                  </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-4">
                    <div class="w-100 cursor-pointer" (click)="uploadPicture()">
                        <img class="upload-style" [src]="image.src" [alt]="image.src">
                    </div>

                    <input type="file" #file accept="image/png, image/gif, image/jpeg" class="d-none" (change)="readFile($event)">
                </div>
                           </div>
        </div>
        <div class="row justify-content-end">
            <div class="col-sm-12 col-md-4 d-flex align-items-center justify-content-end">
                <button mat-flat-button type="submit" class="bg-success text-white w-50">Submit </button>
            </div>
        </div>
    </form>
</div>