<ngx-spinner
  [fullScreen]="true"
  bdColor="#fff"
  size="medium"
  color="#050a4f"
  type="ball-clip-rotate-multiple"
>
</ngx-spinner>
<img
  *ngIf="lapshow"
  class="background-img"
  src="../../../assets/images/login-bg.jpg"
/>
<img
  *ngIf="mobshow"
  class="background-img"
  src="../../../assets/images/mobile.jpg"
/>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <form
        [formGroup]="mobileForm"
        (ngSubmit)="sendOtp()"
        class="p-5"
        *ngIf="stage === 1"
      >
        <div class="row">
          <!-- <div class="col-md-3"></div> -->
          <div class="col-md-12">
            <h3>Enter your registered mobile number</h3>
          </div>
          <!-- <div class="col-md-3"></div> -->
        </div>
        <div class="row">
          <!-- <div class="col-md-3"></div> -->
          <div class="col-md-12">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Mobile Number</mat-label>
              <input
                matInput
                type="text"
                formControlName="phone"
                maxLength="10"
                placeholder="Enter Registered Mobile Number"
              />
              <mat-error> Enter a valid mobile number </mat-error>
            </mat-form-field>
          </div>
          <!-- <div class="col-md-3"></div> -->
        </div>

        <div class="row">
          <!-- <div class="col-md-3"></div> -->
          <div class="col-md-12">
            <button
              type="submit"
              class="btn w-100"
              [disabled]="disableButton || mobileForm.invalid"
            >
              Send Otp
            </button>
          </div>
          <!-- <div class="col-md-3"></div> -->
        </div>
      </form>
    </div>
    <div class="col-md-4"></div>
  </div>

  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <form
        [formGroup]="otpForm"
        (ngSubmit)="verfifyOtp()"
        *ngIf="stage === 2"
        class="p-5"
      >
        <h3>Enter the received otp</h3>
        <div
          class="w-100 d-flex justify-content-between align-items-center otp-group my-3"
        >
          <input
            matInput
            type="text"
            class="form-control"
            (input)="moveToNextField(1)"
            #otpVal1
            formControlName="val1"
            maxLength="1"
          />
          <input
            matInput
            type="text"
            class="form-control"
            (input)="moveToNextField(2)"
            #otpVal2
            formControlName="val2"
            maxLength="1"
          />
          <input
            matInput
            type="text"
            class="form-control"
            (input)="moveToNextField(3)"
            #otpVal3
            formControlName="val3"
            maxLength="1"
          />
          <input
            matInput
            type="text"
            class="form-control"
            (input)="moveToNextField(4)"
            #otpVal4
            formControlName="val4"
            maxLength="1"
          />
        </div>
        <button
          type="submit"
          #otpSubmit
          class="w-100 btn"
          [disabled]="disableButton || otpForm.invalid"
        >
          Verify
        </button>
      </form>
    </div>
    <div class="col-md-4"></div>
  </div>

  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <form
        [formGroup]="passwordForm"
        (ngSubmit)="updatePassword()"
        *ngIf="stage === 3"
        class="p-5"
      >
        <h3>Enter your new password</h3>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Password</mat-label>
          <input
            matInput
            type="password"
            formControlName="password"
            placeholder="Enter password"
          />
          <mat-error> Enter the password </mat-error>
        </mat-form-field>
        <button
          type="submit"
          class="w-100 btn"
          [disabled]="disableButton || passwordForm.invalid"
        >
          Update
        </button>
      </form>
    </div>
    <div class="col-md-4"></div>
  </div>
</div>
