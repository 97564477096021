import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '../shared/shared.module';
import { LoginComponent } from './login/login.component';
import { UserComponent } from './user/user/user.component';
import { UserFormComponent } from './user/user-form/user-form.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { PlanDialogComponent } from './plan-dialog/plan-dialog.component';

@NgModule({
  declarations: [
    LoginComponent,
    UserComponent,
    UserFormComponent,
    ForgetPasswordComponent,
    PlanDialogComponent,
  ],
  imports: [CommonModule, AuthRoutingModule, SharedModule],
})
export class AuthModule {}
