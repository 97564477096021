<div class="container-fluid">
  <div class="row mb-3">
      <button (click)="page = { length: 0, pageIndex: 0, pageSize: 10 }; getOrderList(true, filter.status)" class="btn col-auto text-white  py-2 px-3 mr-1 rounded mt-2"
          [ngClass]="{'bg-danger': !filter.status, 'bg-success': filter.status, 'shadow': filter?.status === selectedStatus}"
          *ngFor="let filter of orderFilters">
          <span class="mr-3">{{ !filter.status ? 'Cancelled' : filter.status == 1 ? 'Queue' : filter.status == 2 ?
              'Confirmed' :
              filter.status == 3 ? 'In Processing'
              : filter.status == 4 ? 'Ready For Delivery' : filter?.status == 5 ? 'Delivered' : filter?.status == 6 ?
              'Reviewed' : 'Payment Settled' }}</span>
          <span>- {{filter?.count}}</span>
      </button>
  </div>
  <div class="row">
      <div class="col-12">
          <h2 class="w-100">Order List </h2>
      </div>
  </div>
  <div class="row align-items-center">
      <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline" class="w-100">
              <mat-label>Search By OrderID, Store Name, User Name, User Phone</mat-label>
              <input matInput [(ngModel)]="searchText" (input)="search?.next(true)" class="input-text"
                  autocomplete="off" />
          </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6 d-flex justify-content-end">
          <button mat-flat-button class="bg-primary text-white" (click)="getOrderList(false)">Refresh</button>
      </div>
  </div>
  <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 w-100">
      <ng-container matColumnDef="sno">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno </th>
          <td mat-cell *matCellDef="let element; let i = index">
              {{ (page.pageIndex *
              page.pageSize) + i + 1}}</td>
      </ng-container>

      <ng-container matColumnDef="order_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Id </th>
          <td mat-cell *matCellDef="let element">{{ element.order_id }}</td>
      </ng-container>

      <ng-container matColumnDef="store">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Store</th>
          <td mat-cell *matCellDef="let element">
              <app-text-ellipsis [length]="15"
                  [text]="element?.store_name">
              </app-text-ellipsis>
          </td>
      </ng-container>

      <ng-container matColumnDef="user_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> User</th>
          <td mat-cell *matCellDef="let element">
              <app-text-ellipsis [length]="15" [text]="element?.name">
              </app-text-ellipsis>
          </td>
      </ng-container>

      <ng-container matColumnDef="is_delivery">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Delivery</th>
          <td mat-cell *matCellDef="let element">
              {{ element?.is_delivery ? 'Yes' : 'No' }} {{ element?.delivery_charge | currency : '$'}}

          </td>
      </ng-container>

      <ng-container matColumnDef="mobile">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Phone</th>
          <td mat-cell *matCellDef="let element"> +{{ element?.dial_code + element?.phone }} </td>
      </ng-container>

      <ng-container matColumnDef="order_items">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Items</th>
          <td mat-cell *matCellDef="let element"> {{element?.items}} </td>
      </ng-container>

      <ng-container matColumnDef="commission">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Commission </th>
          <td mat-cell *matCellDef="let element" class="text-success">{{ element.commission | currency : '$'}}</td>
      </ng-container>

      <ng-container matColumnDef="tax">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Tax</th>
          <td mat-cell *matCellDef="let element" class="text-secondary"> {{element.tax | currency : '$'}} </td>
      </ng-container>

      <ng-container matColumnDef="discount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Discount</th>
          <td mat-cell *matCellDef="let element" class="text-primary"> {{element.discount | currency : '$'}} </td>
      </ng-container>

      <ng-container matColumnDef="order_total">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Total</th>
          <td mat-cell *matCellDef="let element"> {{element.order_total | currency : '$'}} </td>
      </ng-container>

      <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Ordered At</th>
          <td mat-cell *matCellDef="let element">
              <p style="margin: 5px 0px 0px 0px;">{{element.created_at | date: 'MMM d, h:mm a'}}</p>
              <!-- <p style="margin: 0">{{element.created_at | date: 'h:mm a'}}</p> -->
          </td>
      </ng-container>

      <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element" style="width: 25px;font-size: 12px;" class="text-center"
              [ngClass]="{'text-danger': element.status == 0 || element.status == 1, 'text-success': element.status != 1 && element.status != 0}">
              {{ element?.batch_id ? 'Payment Settled' : element?.review ? 'Reviewed' : !element.status ? 'Cancelled'
              : element.status == 1 ? 'Queue' : element.status == 2 ? 'Confirmed' :
              element.status == 3 ? 'In Processing'
              : element.status == 4 ? 'Ready' : 'Delivered' }} </td>
      </ng-container>

      <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="text-center"> Action</th>
          <td mat-cell *matCellDef="let element, index as i" class="text-center">
              <!-- <button mat-flat-button color="primary" (click)="editOrder(element)">
                  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                          fill="white" />
                  </svg>
              </button> -->
              <i class="fa fa-pencil orderEdit" (click)="editOrder(element)" aria-hidden="true"></i>
          </td>
      </ng-container>

      <ng-container matColumnDef="status_action" *ngIf="userRole != 4">
          <th mat-header-cell *matHeaderCellDef class="text-center"> {{ selectedStatus === 6 ? 'Review' : 'Next Stage'
              }}</th>
          <td mat-cell *matCellDef="let element, index as i" class="text-center">
              <button mat-flat-button color="primary" class="bg-danger text-white mx-2" (click)="canelOrder(element)"
                  *ngIf="selectedStatus !== 6 && element.status == 1">
                  Cancel
              </button>
              <button mat-flat-button color="primary" class="bg-success text-white" (click)="updateOrder(element)"
                  *ngIf="selectedStatus !== 6 && element.status && element.status != 5 && !(element?.is_delivery && element?.status === 4)">
                  Next
              </button>
              <span *ngIf="selectedStatus === 6">{{ element?.review?.rating }}</span>
          </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching
          </td>
      </tr>
  </table>

  <mat-paginator [pageSize]="10" [pageIndex]="page?.pageIndex" [length]="page?.length"
      (page)="customePaginator($event)" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
  </mat-paginator>
</div>