import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RestaurantFormComponent } from './restaurant/restaurant-form/restaurant-form.component';
import { ProductFormComponent } from './product/product-form/product-form.component';
import { RestaurantComponent } from './restaurant/restaurant/restaurant.component';
import { ProductComponent } from './product/product/product.component';
import { PromotionComponent } from './promotion/promotion/promotion.component';
import { PromotionFormComponent } from './promotion/promotion-form/promotion-form.component';
import { LocationComponent } from './location/location/location.component';
import { HelpDeskComponent } from './help-des/help-desk/help-desk.component';
import { HelpDeskFormComponent } from './help-des/help-desk-form/help-desk-form.component';
import { PaymentHistoryComponent } from './payment-history/payment-history.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PaymentComponent } from './payment/payment.component';
import { MassCommunicationComponent } from './mass-communication/mass-communication.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { TableComponent } from './table_/table/table.component';
import { ReservationComponent } from './reservation/reservation.component';
import { LoginLogComponent } from './login-log/login-log.component';
import { StorePlanViewComponent } from './store-plan-view/store-plan-view.component';

const routes: Routes = [
  { path: '', component: RestaurantComponent },
  { path: 'restaurant', component: RestaurantComponent },
  { path: 'restaurant-form', component: RestaurantFormComponent },
  { path: 'product', component: ProductComponent },
  { path: 'product-form', component: ProductFormComponent },
  { path: 'promotion', component: PromotionComponent },
  { path: 'promotion-form', component: PromotionFormComponent },
  { path: 'location', component: LocationComponent },
  { path: 'helpdesk', component: HelpDeskComponent },
  { path: 'helpdesk-form', component: HelpDeskFormComponent },
  { path: 'payment-history', component: PaymentHistoryComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'payment', component: PaymentComponent },
  { path: 'mass-email', component: MassCommunicationComponent },
  { path: 'transaction-history', component: TransactionHistoryComponent },
  { path: 'table', component: TableComponent },
  { path: 'reservation', component: ReservationComponent },
  { path: 'login-log', component: LoginLogComponent },
  { path: 'view-plan', component: StorePlanViewComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
