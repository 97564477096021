import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BrandService } from 'src/app/admin/product/brand.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-brand-form',
  templateUrl: './brand-form.component.html',
  styleUrls: ['./brand-form.component.scss'],
})
export class BrandFormComponent implements OnInit {
  @ViewChild('file') file!: ElementRef;
  apiUrl: string = environment.API_URL;
  brandForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(50),
    ]),
    is_active: new FormControl(true),
  });
  image: any = {
    name: '',
    src: '../../../assets/images/placeholder.jpeg',
    file: '',
  };
  brandId: number;
  @Input() set setBrandValue(value) {
    this.brandId = value.id ? value.id : null;
    this.brandForm.controls.name.patchValue(value ? value.name : '');
    this.brandForm.controls.is_active.patchValue(
      value ? value.is_active : false
    );
    if (value.image) this.image.src = `${this.apiUrl}/${value?.image}`;
  }
  @Output() loadBrandList = new EventEmitter();

  constructor(
    private brandService: BrandService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    if (this.brandForm.invalid) return;
    else {
      const data = this.brandForm.value;
      if (this.brandId) data['id'] = this.brandId;
      const formData = new FormData();
      formData.append('Image', this.image.file);
      formData.append('data', JSON.stringify(data));
      this.spinner.show();
      this.brandService.createUpdate(formData).subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res.status) {
            this.toastr.success(res.message, 'Success');
            this.loadBrandList.emit();
          } else this.toastr.error(res.message, 'Error');
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err.message, 'Error');
        }
      );
    }
  }

  readFile(event: any) {
    const file = event.target.files[0];
    this.image.name = file.name;
    this.image.file = file;

    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.image.src = reader.result as string;
      };
    }
  }

  uploadPicture() {
    this.file.nativeElement.click();
  }
}
