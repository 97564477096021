<ngx-spinner
  [fullScreen]="true"
  bdColor="#fff"
  size="medium"
  color="#050a4f"
  type="ball-clip-rotate-multiple"
>
  <p style="font-size: 20px; color: #050a4f">{{ spinnerText }}</p>
</ngx-spinner>
<div class="container-fluid">
  <!-- <button class="btn btn-primary" (click)="pay(1)">Pay 20</button> -->

  <div class="my-2 d-flex align-items-center justify-content-between">
    <h2>Location</h2>
  </div>

  <mat-tab-group>
    <mat-tab label="State">
      <form
        name="stateForm"
        #stateFormDirective="ngForm"
        class="my-4"
        [formGroup]="stateForm"
        (ngSubmit)="onSubmitState(stateFormDirective)"
      >
        <div class="row">
          <div class="col-sm-12 col-md-4">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>State Name </mat-label>
              <input
                matInput
                [(ngModel)]="state.name"
                required
                formControlName="name"
              />
              <mat-error>State Name is Required </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-md-4">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>State Code </mat-label>
              <input
                matInput
                [(ngModel)]="state.code"
                required
                formControlName="code"
              />
              <mat-error>State Code is Required </mat-error>
            </mat-form-field>
          </div>
          <div
            class="col-sm-12 col-md-4 d-flex align-items-center justify-content-center"
          >
            <button
              mat-flat-button
              type="submit"
              class="bg-success text-white w-50"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      <div class="table-responsive">
        <table
          mat-table
          [dataSource]="stateDataSource"
          matSort
          class="mat-elevation-z8 w-100"
        >
          <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno</th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ page.pageIndex * page.pageSize + i + 1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              State Name
            </th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              State Code
            </th>
            <td mat-cell *matCellDef="let element">{{ element.code }}</td>
          </ng-container>

          <!-- <ng-container matColumnDef="created_at">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
                        <td mat-cell *matCellDef="let element"> {{element.created_at | date: 'dd-MM-yyyy'}} </td>
                    </ng-container> -->

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="text-center">
              Action
            </th>
            <td
              mat-cell
              *matCellDef="let element; index as i"
              class="text-center"
            >
              <button mat-flat-button (click)="editState(element)">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                    fill="white"
                  />
                </svg>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="stateColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: stateColumns"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
              {{
                language && language.hasOwnProperty("No data matching")
                  ? language["No data matching"]
                  : "No data matching"
              }}
            </td>
          </tr>
        </table>
      </div>

      <mat-paginator
        [length]="page.length"
        [pageIndex]="page.pageIndex"
        [pageSizeOptions]="pageSizeOptions"
        (page)="customePaginator($event)"
        showFirstLastButtons
      >
      </mat-paginator>
    </mat-tab>
    <mat-tab label="City">
      <form
        name="cityForm"
        #cityFormDirective="ngForm"
        class="my-4"
        [formGroup]="cityForm"
        (ngSubmit)="onSubmitCity(cityFormDirective)"
      >
        <div class="row">
          <div class="col-sm-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>City Name </mat-label>
              <input
                matInput
                [(ngModel)]="city.name"
                required
                formControlName="name"
              />
              <mat-error>City Name is Required </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>State </mat-label>
              <mat-select
                [(ngModel)]="city.state_id"
                required
                formControlName="state"
                (selectionChange)="getCityList(city.state_id)"
              >
                <input
                  (keyup)="search($event.target.value, 'state')"
                  class="form-control w-100"
                  placeholder="Search State"
                />
                <mat-option
                  *ngFor="let state of filteredStateList"
                  [value]="state.id"
                  >{{ state.name }}
                </mat-option>
              </mat-select>
              <mat-error> State is Required </mat-error>
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Pin Code </mat-label>
              <input
                matInput
                [(ngModel)]="city.zip"
                required
                formControlName="zip"
              />
              <mat-error>Pin Code is Required </mat-error>
            </mat-form-field>
          </div>
          <div
            class="col-sm-12 col-md-3 d-flex align-items-center justify-content-center"
          >
            <button
              mat-flat-button
              type="submit"
              class="bg-success text-white w-50"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
      <div class="table-responsive">
        <table
          mat-table
          [dataSource]="cityDataSource.filteredData"
          matSort
          class="mat-elevation-z8 w-100"
        >
          <ng-container matColumnDef="sno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno</th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ page.pageIndex * page.pageSize + i + 1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>City Name</th>
            <td mat-cell *matCellDef="let element">{{ element?.name }}</td>
          </ng-container>

          <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
            <td mat-cell *matCellDef="let element">
              {{ stateFilter[0]?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="zip">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Pin Code</th>
            <td mat-cell *matCellDef="let element">{{ element?.zip }}</td>
          </ng-container>

          <!-- <ng-container matColumnDef="created_at">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
                    <td mat-cell *matCellDef="let element"> {{element.created_at | date: 'dd-MM-yyyy hh-MM-YYYY'}} </td>
                </ng-container> -->

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="text-center">
              Action
            </th>
            <td
              mat-cell
              *matCellDef="let element; index as i"
              class="text-center"
            >
              <button mat-flat-button (click)="editCity(element)">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                    fill="white"
                  />
                </svg>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="cityColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: cityColumns"></tr>

          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">
              {{
                language && language.hasOwnProperty("No data matching")
                  ? language["No data matching"]
                  : "No data matching"
              }}
            </td>
          </tr>
        </table>
      </div>

      <mat-paginator
        [length]="page.length"
        [pageIndex]="page.pageIndex"
        [pageSizeOptions]="pageSizeOptions"
        (page)="customePaginator($event)"
        showFirstLastButtons
      >
      </mat-paginator>
    </mat-tab>
  </mat-tab-group>
</div>
