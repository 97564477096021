<main
  class="main-layout"
  (mousemove)="resetLogoutTimer()"
  (click)="resetLogoutTimer()"
  (keypress)="resetLogoutTimer()"
>
  <app-sidebar
    *ngIf="isLogin && (user?.role == 1 || user?.role == 6 || user?.role == 7 || user?.role == 8)"
    [isMobMenuActive]="isMobMenuActive"
    (menuChangeEvent)="onMenuTogglerChange($event)"
  >
  </app-sidebar>
  <app-sidebar
    *ngIf="
      isLogin &&
      (user?.role != 1 || user?.role != 6 || user?.role != 7 || user?.role != 8) &&
      ed >= cd
    "
    [isMobMenuActive]="isMobMenuActive"
    (menuChangeEvent)="onMenuTogglerChange($event)"
  >
  </app-sidebar>
  <div
    class="content-area"
    [ngClass]="{
      'logged-in':
        (isLogin && (user?.role == 1 || user?.role == 6 || user?.role == 7 || user?.role == 8)) ||
        (isLogin &&
          (user?.role != 1 || user?.role != 6 || user?.role != 7 || user?.role != 8) &&
          ed >= cd),
      'menu-collapsed': !isMenuOpen
    }"
  >
    <div
      class="main-navbar-close-layer"
      [ngClass]="{ 'mob-menu-active': isMobMenuActive }"
      (click)="closeMobMenu()"
    ></div>
    <app-header
      *ngIf="
        isLogin && (user?.role == 1 || user?.role == 6 || user?.role == 7 || user?.role == 8)
      "
      [isMenuOpen]="isMenuOpen"
      (mobMenuTogglerEvent)="onMobMenuChange($event)"
    >
    </app-header>
    <app-header
      *ngIf="
        isLogin &&
        (user?.role != 1 || user?.role != 6 || user?.role != 7 || user?.role != 8) &&
        ed >= cd
      "
      [isMenuOpen]="isMenuOpen"
      (mobMenuTogglerEvent)="onMobMenuChange($event)"
    >
    </app-header>
    <router-outlet></router-outlet>
  </div>
</main>
