import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { environment } from '../../../environments/environment';
import { OrderService } from '../order.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import * as moment from 'moment';
import { DeliveryPersonService } from 'src/app/settings/delivery-person-list/delivery-person.service';
// declare const L: any;
// import * as L from 'leaflet';
// declare const google: any;/
import { Loader, LoaderOptions } from 'google-maps';
const options: LoaderOptions = {/* todo */ };
const loader = new Loader('AIzaSyCDZ61ibrKPUFbhjTFIcfwF2g9DNcUvutI', options);
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

/**
 * @title Dialog with header, scrollable content and actions
 */
@Component({
    selector: 'order-detail',
    styleUrls: ['./order-detail.component.scss'],
    templateUrl: './order-detail.component.html'
})
export class OrderDetailComponent {
    private geolocationSubject = new Subject<any>();
    private geolocationSubscription: any;
  
    // map:any;
    orderDetail: any;
    apiUrl: string = '';
    canEditOrder: boolean = false;
    order: any;
    @ViewChild('logModal') private logModal!: TemplateRef<any>;
    @ViewChild('mapModal') private mapModal!: TemplateRef<any>;

    userRole: number = 0;
    public orderStatusLogs!: any;
    private orderItemId!: number;
    public deliveryPersons;
    public delPerson!: number;
    message: any;
    roomName: any;
    longitude: number;
    latitude: number;
    startPoint: any;
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<OrderDetailComponent>, private dialog: MatDialog, private orderService: OrderService, private spinner: NgxSpinnerService, private toaster: ToastrService, private appService: AppService, private deliveryPersonService: DeliveryPersonService, private http: HttpClient) {
        this.orderDetail = {};
        Object.assign(this.orderDetail, data);
        this.apiUrl = environment.API_URL;
        this.userRole = this.appService.getUserRole();
        console.log(this.userRole)
        this.findOrderInfo();
        // this.initMap();s

    }

    private getDeliveryPersons(): void {
        const data = {
            assignDelivery: true
        }
        this.spinner.show();
        this.deliveryPersonService.getDeliveryPerson(data).subscribe({
            next: (res: any) => {
                this.spinner.hide();
                if (res?.status) {
                    this.deliveryPersons = res?.data;
                }
            },
            error: (err) => {
                this.spinner.hide();
            }
        })
    }

    removeOrderItem(index: number) {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            data: 'Are you sure want to remove this item ?'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.order.order_items.splice(index, 1);
                this.updateOrderTotal();
            }
        });
    }

    changeOrderDetails(index: number, type: boolean) {
        if (this.canEditOrder) {
            if (type) {
                console.log(this.order.order_items[index].store_item_variant.has_limit)
                if (this.order.order_items[index].store_item_variant.has_limit == 1) {
                    if (this.order.order_items[index].store_item_variant.limit > this.order.order_items[index].qty) {
                        if (this.order.order_items[index].store_item_variant.quantity >= this.order.order_items[index].qty) {

                            this.order.order_items[index].qty++;
                            this.updateOrderTotal();
                        }
                        else {
                            this.toaster.error('out of stock!...')

                        }

                    }
                    else {
                        this.toaster.error('limit exceeds!...')

                    }
                } else {
                    this.order.order_items[index].qty++;
                    this.updateOrderTotal();

                }
            } else {
                if (this.order.order_items[index].qty === 1) {
                    this.removeOrderItem(index);
                }
                else {
                    this.order.order_items[index].qty--;
                }
                this.updateOrderTotal();

            }
        }
        else
            this.openConfirmationDialog()
    }

    openConfirmationDialog() {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            data: 'Are you sure want to edit this order ?'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.canEditOrder = true;
            }
        });
    }

    private updateOrderItem() {
        this.spinner.show();
        const data = { order_id: this.orderDetail?.id, order_item_id: this.orderDetail.id };
        this.orderService.updateOrderItem(data).subscribe((res: any) => {
            this.spinner.hide();
            if (res.status) {
                this.toaster.success(res.message, 'Success');
                this.findOrderInfo();
            }
            else
                this.toaster.error(res.message, 'Error')
        }, (err: any) => {
            this.spinner.hide();
            this.toaster.error(err.message, 'Error')
        })
    }

    updateOrderTotal() {
        if (this.order?.order_items && this.order?.order_items?.length) {
            this.order.order_total = 0;
            this.order.discount = 0;
            this.order.tax = 0;
            this.order.commission = 0;
            this.order?.order_items.map((order_item: any) => {
                if (!order_item?.isTitle) {
                    this.order.order_total += (order_item.price * order_item.qty)
                }
            });
            this.order.commission = this.order?.store_commission_settings?.commission ? (this.order?.store_commission_settings?.commission * this.order?.order_total / 100) : 0;
            if (this.order.order_total >= this.order?.store_commission_settings?.min_order_amount) {
                this.order.discount = this.order?.store_commission_settings?.discount ? (this.order?.store_commission_settings?.discount * this.order?.order_total / 100) : 0;
            }
            this.order.tax = this.order?.store_commission_settings?.tax ? (this.order?.store_commission_settings?.tax * this.order?.order_total / 100) : 0;
            this.order.order_total += this.order.tax - this.order.discount;
            console.log(this.order)
        }
    }

    capture(orderData: any) {
        this.spinner.show();
        const data = { retref: orderData.retref, authcode: orderData.authcode, order_id: orderData.order_id };
        this.orderService.capture(data).subscribe((res: any) => {
            this.spinner.hide();
            if (res.status) {
                this.toaster.success(res.message, 'Success');
                const order: any = { order_status: orderData.status, order_id: orderData.id, store_id: orderData.store_id, user_id: orderData.user_id };
                this.dialogRef.close(this.orderDetail);
            }
            else
                this.toaster.error(res.message, 'Error')
        }, (err: any) => {
            this.spinner.hide();
            this.toaster.error(err.message, 'Error')
        })
    }

    canelOrder() {
        this.spinner.show();
        const data = { order_id: this.orderDetail.id };
        this.orderService.cancelOrder(data).subscribe((res: any) => {
            this.spinner.hide();
            if (res.status) {
                this.toaster.success(res.message, 'Success');
                // const order: any = { order_status: this.orderDetail.status, order_id: this.orderDetail.id, store_id: this.orderDetail.store_id, user_id: this.orderDetail.user_id };
                // this.orderService.createOrderWithSocket(order);
                this.dialogRef.close({ updateOrderList: true });
            }
            else
                this.toaster.error(res.message, 'Error')
        }, (err: any) => {
            this.spinner.hide();
            this.toaster.error(err.message, 'Error')
        })
    }

    confirmOrder() {
        // let payment = false;
        // let order = { order_status: this.orderDetail.status, order_id: this.orderDetail.id, store_id: this.orderDetail.store_id, user_id: this.orderDetail.user_id };
        // if (this.orderDetail.status == 1) {
        //     this.orderDetail.status = 2;
        //     const order = { status: this.orderDetail.status, order_id: this.orderDetail.id, payment_status: this.orderDetail.payment_status, retref: this.orderDetail.retref, authcode: this.orderDetail.authcode, user_id: this.orderDetail.user_id };
        //     payment = true;
        //     // this.orderService.createPaymentWithSocket(order);
        //     // this.capture(order);
        // }
        // else if (this.orderDetail.status == 2)
        //     this.orderDetail.status = 3;
        // else if (this.orderDetail.status == 3)
        //     this.orderDetail.status = 4;
        // if (this.canEditOrder) {
        //     this.orderDetail.edited = true;
        // }
        // order.order_status = this.orderDetail.status;
        // if (!payment)
        //     this.orderService.createOrderWithSocket(order);
        // if (this.orderDetail.status != 2)
        //     this.dialogRef.close(this.orderDetail);
        const data = {
            status: this.orderDetail.status + 1,
            id: this.orderDetail?.id,
            is_delivery: this.order?.is_delivery,
            delPerson: this.delPerson,
            orderDetail: this.order,
            edited: this.canEditOrder
        }
        this.dialogRef.close(data);
    }

    public assignDeliveryPerson(): void {
        const data = {
            status: this.orderDetail.status,
            id: this.orderDetail?.id,
            is_delivery: this.order?.is_delivery,
            delPerson: this.delPerson
        }
        this.dialogRef.close(data);
    }

    findOrderInfo() {
        const data = { id: this.orderDetail.id, created_at: this.orderDetail?.created_at }
        this.spinner.show();
        this.orderService.findOrderInfo(data).subscribe((res: any) => {

            this.spinner.hide();
            if (res.status) {
                if (this.orderDetail.id != this.roomName) {
                    this.leaveRoom()
                }
                this.receiveMsg()
                this.order = res.data;
                if (this.userRole == 1) {
                    const store_id = [];
                    const orderItems = [];
                    this.order.order_items.map((orderItem, index) => {
                        orderItem.editOrgOrder = false;
                        if (!store_id.some(store => store == orderItem.store_id)) {
                            const newRec = {
                                isTitle: true,
                                vendor: orderItem.store_item_variant.store.name,
                                status: orderItem.status,
                                statusText: this.order?.batch_id ? 'Payment Settled' : this.order?.review ? 'Reviewed' : !orderItem.status ? 'Cancelled' : orderItem.status == 1 ? 'Queue' : orderItem.status ==
                                    2 ? 'Confirmed' : orderItem.status == 3 ? 'In itchen'
                                    : orderItem.status == 4 ? 'Ready For Delivery' : 'Delivered'
                            };
                            orderItems.push(newRec);
                            orderItems.push(orderItem);
                            // this.order.order_items.splice(index, 0, newRec);
                            store_id.push(orderItem.store_id);
                        }
                        else
                            orderItems.push(orderItem);
                    });
                    this.order.order_items = orderItems;
                }

                let index = 1;
                this.order?.order_items?.forEach(val => {
                    if (!val?.isTitle) {
                        val.index = index;
                        index++;
                    }
                });
                this.order.total_items = index - 1;
                this.order.statusText = !this.order.order_items[0].status ? 'Cancelled' : this.order.order_items[0].status == 1 ? 'Queue' : this.order.order_items[0].status ==
                    2 ? 'Confirmed' : this.order.order_items[0].status == 3 ? 'In itchen'
                    : this.order.order_items[0].status == 4 ? 'Ready For Delivery' : 'Delivered';
                if (this.order?.is_delivery) {
                    this.getDeliveryPersons();
                }
                this.delPerson = this.order?.delivery_info?.delivery_person_id;
            }
        }, (err: any) => {
            this.spinner.hide();
            this.toaster.error(err.message, 'Error');
        })
    }
    receiveMsg() {
        this.orderService.onMessage().subscribe(data => {
            this.message = data;
            console.log('data', data)
        });
    }
    returnJson(data: any, key: string) {
        return JSON.parse(data)[key];
    }

    openLogModal() {
        this.findOrderLog();
        this.dialog.open(this.logModal, {
            width: '40vw !important',
            height: '68vh', minWidth: '60vw'
        });

    }
    initMap() {
        console.log(loader.load)
        // window.location.reload()s
        loader.load().then((google) => {
            const map = new google.maps.Map(document.getElementById('map'), {
                center: { lat: -34.397, lng: 150.644 },
                zoom: 8,
            });
            console.log(map)
            
        })


    }
    calculateAndDisplayRoute(directionsService, directionsRenderer) {
        directionsService
            .route({
                origin: this.startPoint,

                destination: {
                    // query: 'kumbakonam'

                      query: `${this.order.delivery_info.address1},${this.order.delivery_info.city.name},${this.order.delivery_info.state.name}`
                },
                travelMode: google.maps.TravelMode.DRIVING,
            })
            .then((response) => {
                console.log('res', response)
                directionsRenderer.setDirections(response);
            })
            .catch((e) => this.toaster.error("Directions request failed due to " + status));
    }
    initMap1() {
        console.log(loader.load)
        // window.location.reload()s
        loader.load().then((google) => {
            const map = new google.maps.Map(document.getElementById('map'), {
                center: { lat: -34.397, lng: 150.644 },
                zoom: 8,
            });
            console.log(map)
            const directionsService = new google.maps.DirectionsService();
            const directionsRenderer = new google.maps.DirectionsRenderer();
            directionsRenderer.setMap(map);
            if (map.getZoom()) {
                console.log(directionsService, directionsRenderer)
                this.calculateAndDisplayRoute1(directionsService, directionsRenderer);
            }
            else {
                window.location.reload()
            }
        })


    }
    calculateAndDisplayRoute1(directionsService, directionsRenderer) {
        directionsService
            .route({
                origin:{
                    query:this.startPoint,
                } ,

                destination: {
                    // query: 'kumbakonam'

                      query: `${this.order.delivery_info.address1},${this.order.delivery_info.city.name},${this.order.delivery_info.state.name}`
                },
                travelMode: google.maps.TravelMode.DRIVING,
            })
            .then((response) => {
                console.log('res', response)
                directionsRenderer.setDirections(response);
            })
            .catch((e) => this.toaster.error("Directions request failed due to " + status));
    }
    sendMessage(): void {
        // if (this.message) {

        //         let seconds = 0;

        // const interval = setInterval(() => {
        this.message = {lat:`${this.latitude}`,long:`${this.longitude}`};
        console.log(`${this.message} seconds have passed.${this.orderDetail.id}`);
        this.orderService.sendMessage(this.message, this.orderDetail.id);

        // },5000)
        //     this.message='172.569'
        //   this.orderService.sendMessage(this.message);
        // }
    }
    getLocation() {
        // const interval = setInterval(() => {

            if ('geolocation' in navigator) {
                navigator.geolocation.watchPosition(
                    (position) => {
                        console.log(position);
                        this.geolocationSubject.next(position);

                    },
                    (error) => {
                        alert(`Geolocation error: ${error.message}`)
                    }
                );
            } else {
                alert('Geolocation is not supported by this browser.')
            }
        // }, 150000)

        this.geolocationSubject.pipe(
            debounceTime(15000) // Adjust the debounce time (in milliseconds) as needed
          ).subscribe((position) => {
            // Handle the debounced geolocation update
            
            this.latitude = position.coords.latitude;
            this.longitude = position.coords.longitude;
            this.toaster.success(`${this.latitude},${this.longitude}`)
            const map = new google.maps.Map(document.getElementById('map'))
            this.startPoint = new google.maps.LatLng(this.latitude, this.longitude)
            console.log('test', this.startPoint)
            const directionsService = new google.maps.DirectionsService();
            const directionsRenderer = new google.maps.DirectionsRenderer();
            directionsRenderer.setMap(map);

                console.log(directionsService, directionsRenderer)
                this.calculateAndDisplayRoute(directionsService, directionsRenderer);

            // const url=`https://www.mapquestapi.com/geocoding/v1/reverse?key=Cmjtd|luur2108n1,7w=o5-gz8a&location=${this.latitude},${this.longitude}&outFormat=json&thumbMaps=false`
            // this.http.get(url).subscribe((res:any)=>{
            // this.startPoint= new google.maps.LatLng(`${this.latitude},${this.longitude}`);
            // this.initMap()
            this.sendMessage()
            // })



          });
    }
    leaveRoom() {
        this.roomName = this.orderDetail.id; // Replace with the actual room name
        this.orderService.leaveRoom(this.roomName);
    }
    openMapModal() {
        const userConfirmed = confirm('Are you reached restaurant?.if yes pls click ok');

        if (userConfirmed) {
            // User clicked "OK" in the confirmation dialog
            // Perform the action you want to execute when the user confirms
            this.getLocation()
            this.initMap()

        } else {

            this.initMap1()
            this.startPoint = `${this.order.order_items[0].store_item_variant.store.address1},${this.order.order_items[0].store_item_variant.store.address2},${this.order.order_items[0].store_item_variant.store.address3},${this.order.order_items[0].store_item_variant.store.city.name},${this.order.order_items[0].store_item_variant.store.state.name}`
            // this.getLocation()
            // User clicked "Cancel" in the confirmation dialog
            // Handle the case where the user decided not to proceed
        }

        //   this.findOrderInfo();
        this.dialog.open(this.mapModal, {
            width: '40vw !important',
            height: '68vh', minWidth: '60vw'
        });
    }
 ngOnDestroy() {
    if (this.geolocationSubscription) {
      this.geolocationSubscription.unsubscribe();
    }
  }
    closeLogModal() {
        this.dialog.closeAll();
    }

    findOrderLog() {
        const data = { id: this.orderDetail.id }
        this.spinner.show();
        this.orderService.findOrderLog(data).subscribe((res: any) => {
            this.spinner.hide();
            this.orderStatusLogs = [];
            if (res?.status && res?.data?.length) {
                const store_ids = [];
                res?.data?.sort((a, b) => a?.store_id - b?.store_id)
                    .map(logs => {
                        if (!logs?.changedLog && this.userRole == 1 && (!store_ids?.length || (store_ids?.length && !store_ids.some(val => val === logs?.store_id)))) {
                            this.orderStatusLogs.push({
                                storeHeader: true,
                                storeName: logs?.store?.name
                            });
                            store_ids.push(logs?.store_id);
                        }
                        this.orderStatusLogs.push(logs);

                    });
                console.log(this.orderStatusLogs);
            }
        }, (err: any) => {
            this.spinner.hide();
            this.toaster.error(err.message, 'Error');
        })
    }

    public printOrder(): void {
        const printContent = document.querySelector("#printSection");
        const WindowPrt = window.open('', '', 'left=0,top=0,width=1800,height=1800,toolbar=0,scrollbars=0,status=0');
        WindowPrt.document.write(printContent?.innerHTML);
        WindowPrt.document.close();
        WindowPrt.focus();
        WindowPrt.print();
        WindowPrt.close();
    }


}