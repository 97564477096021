import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  isMobMenuActive: boolean = false;
  isMenuOpen: boolean = true;
  menuItems = [];
  user: any;
  @Input() isMobProductActive = false;
  @Input() login: any;
  @Output() menuChangeEvent = new EventEmitter();
  siebarList: any = [];
  nav: any;
  constructor(
    private appService: AppService,
    private router: Router,
    public toastr: ToastrService
  ) {}

  ngOnInit() {
    this.getRole();
    this.showNavbar();
    console.log(this.login);
  }

  menuToggler(): void {
    this.isMenuOpen = !this.isMenuOpen;
    this.menuChangeEvent.emit(this.isMenuOpen);
  }

  showNavbar() {
    const show = localStorage.getItem('showNav');
    console.log(show);
    if (show) {
      this.nav = true;
    } else {
      this.nav = false;
    }
  }

  getRole() {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user?.role == 1 || this.user?.role == 6 || this.user?.role == 7)
      this.menuItems = [
        {
          path: 'admin/dashboard',
          title: 'Dashboard',
          icon: 'fa fa-tachometer',
        },
        { path: 'admin/product', title: 'Product', icon: 'fa fa-product-hunt' },
        { path: 'admin/restaurant', title: 'Stores', icon: 'fa fa-cart-plus' },
        { path: 'order/list', title: 'Orders', icon: 'fa fa-first-order' },
        { path: 'auth/users', title: 'Super Admin', icon: 'fa fa-user' },
        {
          path: 'admin/mass-email',
          title: 'Mass Communication',
          icon: 'fa fa-envelope-o',
        },
        {
          path: 'admin/promotion',
          title: 'Promotion',
          icon: 'fa fa-product-hunt',
        },
        { path: 'admin/location', title: 'Location', icon: 'fa fa-map-marker' },
        {
          path: 'admin/helpdesk',
          title: 'Help Desk',
          icon: 'fa fa-info-circle',
        },
        {
          path: 'admin/payment',
          title: 'Payment Batch',
          icon: 'fa fa-credit-card-alt',
        },
        {
          path: 'admin/transaction-history',
          title: 'Transaction History',
          icon: 'fa fa-history',
        },
        {
          path: 'reports/user-report',
          title: 'User Report',
          icon: 'fa fa-users',
        },
        {
          path: 'reports/store-plan-report',
          title: 'Store Plan Report',
          icon: 'fa fa-money',
        },
        { path: 'admin/login-log', title: 'Login Log', icon: 'fa fa-archive' },
        { path: 'settings/delivery', title: 'Delivery Person', icon: 'fa fa-motorcycle' },
      ];
    else if (this.user?.role == 2)
      this.menuItems = [
        {
          path: 'admin/dashboard',
          title: 'Dashboard',
          icon: 'fa fa-tachometer',
        },
        {
          path: 'admin/view-plan',
          title: 'Store Plan',
          icon: 'fa fa-product-hunt',
        },
        { path: 'auth/users', title: 'User Management', icon: 'fa fa-user' },
        { path: 'admin/product', title: 'Product', icon: 'fa fa-product-hunt' },
        { path: 'order/list', title: 'Orders', icon: 'fa fa-first-order' },
        {
          path: 'admin/promotion',
          title: 'Promotion',
          icon: 'fa fa-product-hunt',
        },
        {
          path: 'admin/helpdesk',
          title: 'Help Desk',
          icon: 'fa fa-info-circle',
        },
        {
          path: 'settings/restaurant',
          title: 'Settings',
          icon: 'fa fa-cart-plus',
        },
        {
          path: 'settings/notification',
          title: 'Notification Settings',
          icon: 'fa fa-bell',
        },
        {
          path: 'admin/payment',
          title: 'Payment Batch',
          icon: 'fa fa-credit-card-alt',
        },
        {
          path: 'admin/transaction-history',
          title: 'Transaction History',
          icon: 'fa fa-history',
        },
        {
          path: 'reports/user-report',
          title: 'User Report',
          icon: 'fa fa-user',
        },
        { path: 'reports', title: 'Price Report', icon: 'fa fa-history' },
        {
          path: 'user-req-prod/home',
          title: 'Requested Products',
          icon: 'fa fa-history',
        },
        { path: 'admin/login-log', title: 'Login Log', icon: 'fa fa-archive' },
      ];
    else if (this.user?.role == 3)
      this.menuItems = [
        { path: 'admin/product', title: 'Product', icon: 'fa fa-product-hunt' },
        { path: 'order/list', title: 'Orders', icon: 'fa fa-first-order' },
        {
          path: 'admin/helpdesk',
          title: 'Help Desk',
          icon: 'fa fa-info-circle',
        },
        {
          path: 'settings/restaurant',
          title: 'Settings',
          icon: 'fa fa fa-cutlery',
        },
      ];

      else if (this.user?.role == 8) {
        this.menuItems = [
          { path: 'order/del-orders', title: 'Orders', icon: 'fa fa-first-order' }
        ];
      }
      
    else
      this.menuItems = [
        { path: 'order/list', title: 'Orders', icon: 'fa fa-first-order' },
        {
          path: 'admin/helpdesk',
          title: 'Help Desk',
          icon: 'fa fa-info-circle',
        },
      ];
  }

  @Output() menuChange = new EventEmitter();

  menuChanged(index) {
    $('#sidemenu').children().removeClass('active');
    $('#li' + index).addClass('active');
    this.menuChange.emit();
  }
}
