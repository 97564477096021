import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';
import { LocationService } from '../location.service';
import {
  FormControl,
  FormGroup,
  Validators,
  FormGroupDirective,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/auth/user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit {
  panelOpenState: boolean = true;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  stateDataSource: any = new MatTableDataSource();
  cityDataSource: any = new MatTableDataSource();
  stateColumns: string[] = ['sno', 'name', 'code', 'action'];
  cityColumns: string[] = ['sno', 'state', 'name', 'zip', 'action'];
  stateList: any = [];
  filteredStateList: any = [];
  cityList = [];
  pageSizeOptions = [5, 10, 20];
  pageEvent: PageEvent;
  page = { length: 0, pageIndex: 0, pageSize: 5 };
  stateFilter: any;
  state: any = {
    id: null,
    name: '',
    code: '',
    created_at: new Date(),
  };
  city: any = {
    id: null,
    name: '',
    state_id: '',
    zip: '',
    created_at: new Date(),
  };
  stateForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    code: new FormControl('', [Validators.required]),
  });
  cityForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    zip: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$'),
      Validators.maxLength(6),
      Validators.minLength(6),
    ]),
  });
  spinnerText: string = '';
  handler: any = null;

  constructor(
    private spinner: NgxSpinnerService,
    private locationService: LocationService,
    private toastr: ToastrService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.getStateList();
  }

  pay(amount: any) {
    var handler = (<any>window).StripeCheckout.configure({
      key: 'pk_live_51KXrPRSGFBQQGSY2GfdCjlRq3HjjegFF8FkFSQKybWpPW0q9ssLRvV2qjG911wELH8ozhTBSMA33fLDaTsWQEyuH00TJtzldN7',
      locale: 'auto',
      token: function (token: any) {
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
        console.log(token);
        alert('Token Created!!');
        const data = {
          payment_reference: token?.id,
          amount: amount,
          request_params: {},
          response_params: JSON.stringify(token),
          payment_status: Object.keys(token)?.length ? 1 : 0,
        };
      },
    });

    handler.open({
      name: 'Demo Site',
      description: '2 widgets',
      amount: amount,
    });
  }

  loadStripe() {
    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement('script');
      s.id = 'stripe-script';
      s.type = 'text/javascript';
      s.src = 'https://checkout.stripe.com/checkout.js';
      s.onload = () => {
        this.handler = (<any>window).StripeCheckout.configure({
          key: 'pk_test_51HxRkiCumzEESdU2Z1FzfCVAJyiVHyHifo0GeCMAyzHPFme6v6ahYeYbQPpD9BvXbAacO2yFQ8ETlKjo4pkHSHSh00qKzqUVK9',
          locale: 'auto',
          token: function (token: any) {
            // You can access the token ID with `token.id`.
            // Get the token ID to your server-side code for use.
            console.log(token);
            alert('Payment Success!!');
          },
        });
      };

      window.document.body.appendChild(s);
    }
  }

  ngAfterViewInit() {}

  getStateList() {
    this.spinnerText = 'Fetching State Data';
    this.spinner.show();
    this.userService.getStates().subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.stateList = res.data;
          this.filteredStateList = [...this.stateList];
          this.stateDataSource = new MatTableDataSource(this.stateList);
          this.page.length = this.stateList.length;
          this.stateDataSource.sort = this.sort;
          this.stateDataSource.paginator = this.paginator;
        } else this.toastr.error(res.message, 'Error');
      },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    );
  }

  editState(element: any) {
    Object.assign(this.state, element);
  }

  editCity(element: any) {
    Object.assign(this.city, element);
  }

  getCityList(state_id: number) {
    this.spinnerText = 'Fetching City Data';
    this.spinner.show();
    const data = { state_id: state_id };
    this.userService.getCities(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.stateList;
          console.log(this.stateList);
          this.stateFilter = this.stateList.filter((res: any) => {
            return res.id == data.state_id;
          });
          // console.log(stateFilter);

          this.cityList = res.data;
          this.cityDataSource = new MatTableDataSource(this.cityList);
          this.page.length = this.cityList.length;
          this.cityDataSource.sort = this.sort;
          this.cityDataSource.paginator = this.paginator;
          console.log(this.cityDataSource.filteredData);
        } else this.toastr.error(res.message, 'Error');
      },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    );
  }

  customePaginator(event) {
    this.page.pageIndex = event.pageIndex;
    this.page.pageSize = event.pageSize;
  }

  search(value: string, type: string) {
    let filter = value;
    if (type == 'state') {
      this.filteredStateList = this.stateList.filter((state) => {
        let lowerCase = state.name.toLocaleLowerCase();
        return state.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
  }

  onSubmitState(formDirective: any) {
    if (this.stateForm.invalid) return;
    else {
      this.spinnerText = this.state.id ? 'Updating State' : 'Creating State';
      this.spinner.show();
      this.locationService.createUpdateState(this.state).subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res.status) {
            formDirective.resetForm();
            this.stateForm.reset();
            this.toastr.success(res.message, 'Success');
            this.getStateList();
          } else this.toastr.error(res.message, 'Error');
        },
        (err: any) => {
          this.spinner.hide();
          this.toastr.error(err.message, 'Error');
        }
      );
    }
  }

  onSubmitCity(formDirective: any) {
    if (this.cityForm.invalid) return;
    else {
      this.spinnerText = this.state.id ? 'Updating City' : 'Creating City';
      this.spinner.show();
      this.locationService.createUpdateCity(this.city).subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res.status) {
            const state_id = this.city.state_id;
            formDirective.resetForm();
            this.cityForm.reset();
            this.getCityList(state_id);
            this.toastr.success(res.message, 'Success');
          } else this.toastr.error(res.message, 'Error');
        },
        (err: any) => {
          this.spinner.hide();
          this.toastr.error(err.message, 'Error');
        }
      );
    }
  }
}
