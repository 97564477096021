<!-- <div class="row">
  <div class="col-md-12 text-right mb-2">
    <a class="px-1 mx-1 border-bottom text-danger filterBtn"
      [ngStyle]="{'color':selectedFilter === '1' ? 'green' : 'blue' }" (click)="filterData(1)">T</a>
    <a class="px-1 mx-1 border-bottom text-danger filterBtn"
      [ngStyle]="{'color':selectedFilter === '3' ? 'green' : 'blue' }" (click)="filterData(3)">3D</a>
    <a class="px-1 mx-1 border-bottom text-danger filterBtn"
      [ngStyle]="{'color':selectedFilter === '7' ? 'green' : 'blue' }" (click)="filterData(7)">1W</a>
    <a class="px-1 mx-1 border-bottom text-danger filterBtn"
      [ngStyle]="{'color':selectedFilter === '30' ? 'green' : 'blue' }" (click)="filterData(30)">1M</a>
  </div>
</div> -->

<!-- <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle [ngSwitch]="node.level">
        <table class="table" *ngSwitchCase="1">
            <thead class="bg-primary text-white" *ngIf="node.head">
                <tr>
                    <th class="px-2 py-3"> Batch</th>
                    <th class="px-2 py-3"> Total Amount </th>
                    <th class="px-2 py-3"> Total Order </th>
                    <th class="px-2 py-3"> Paid Amount </th>
                    <th class="px-2 py-3"> Comission Amount </th>
                    <th class="px-2 py-3"> From Date </th>
                    <th class="px-2 py-3"> To Date </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="px-2 py-3"> {{node.batch + 'i ' + i}} </td>
                    <td class="px-2 py-3"> {{node.total_amount | currency: '$'}} </td>
                    <td class="px-2 py-3"> {{node.total_order}} </td>
                    <td class="px-2 py-3"> {{node.paid_amount | currency: '$'}} </td>
                    <td class="px-2 py-3 text-success"> {{node.comission_amount | currency: '$'}} </td>
                    <td class="px-2 py-3"> {{node.from_date}} </td>
                    <td class="px-2 py-3"> {{node.to_date}} </td>
                </tr>
            </tbody>
        </table>
        <table class="table" *ngSwitchCase="2">
            <thead class="bg-primary text-white" *ngIf="node.head">
                <tr>
                    <th class="px-2 py-3"> Restaurant Name </th>
                    <th class="px-2 py-3"> Total Amount </th>
                    <th class="px-2 py-3"> Total Order </th>
                    <th class="px-2 py-3"> Paid Amount </th>
                    <th class="px-2 py-3"> Comission Amount </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="px-2 py-3"> {{node.restaurant_name + 'i ' + i}} </td>
                    <td class="px-2 py-3"> {{node.total_amount | currency: '$'}} </td>
                    <td class="px-2 py-3"> {{node.total_order}} </td>
                    <td class="px-2 py-3"> {{node.paid_amount | currency: '$'}} </td>
                    <td class="px-2 py-3 text-success"> {{node.comission_amount | currency: '$'}} </td>
                </tr>
            </tbody>
        </table>
        <table class="table" *ngSwitchCase="3">
            <thead class="bg-primary text-white" *ngIf="node.head">
                <tr>
                    <th class="px-2 py-3"> Order Amount </th>
                    <th class="px-2 py-3"> Order Date </th>
                    <th class="px-2 py-3"> Order Item Count </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="px-2 py-3"> {{node.order_amount}} </td>
                    <td class="px-2 py-3"> {{node.order_date}} </td>
                    <td class="px-2 py-3"> {{node.children.length}} </td>
                </tr>
            </tbody>
        </table>
        <table class="table" *ngSwitchCase="4">
            <thead class="bg-primary text-white" *ngIf="node.head">
                <tr>
                    <th class="px-2 py-3"> Menu Name </th>
                    <th class="px-2 py-3"> Price </th>
                    <th class="px-2 py-3"> Quantity </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="px-2 py-3"> {{node.menu_name}} </td>
                    <td class="px-2 py-3"> {{node.price |  currency : '$'}} </td>
                    <td class="px-2 py-3"> {{node.qty}} </td>
                </tr>
            </tbody>
        </table>
    </mat-tree-node>
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild; let i = index" [ngSwitch]="node.level">
        <div class="mat-tree-node">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>
            <table class="table" *ngSwitchCase="1">
                <thead class="bg-primary text-white" *ngIf="node.head">
                    <tr>
                        <th class="px-2 py-3"> Batch </th>
                        <th class="px-2 py-3"> Total Amount </th>
                        <th class="px-2 py-3"> Total Order </th>
                        <th class="px-2 py-3"> Paid Amount </th>
                        <th class="px-2 py-3"> Comission Amount </th>
                        <th class="px-2 py-3"> From Date </th>
                        <th class="px-2 py-3"> To Date </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="px-2 py-3"> {{node.batch}} </td>
                        <td class="px-2 py-3"> {{node.total_amount | currency: '$'}} </td>
                        <td class="px-2 py-3"> {{node.total_order}} </td>
                        <td class="px-2 py-3"> {{node.paid_amount | currency: '$'}} </td>
                        <td class="px-2 py-3 text-success"> {{node.comission_amount | currency: '$'}} </td>
                        <td class="px-2 py-3"> {{node.from_date}} </td>
                        <td class="px-2 py-3"> {{node.to_date}} </td>
                    </tr>
                </tbody>
            </table>
            <table class="table" *ngSwitchCase="2">
                <thead class="bg-primary text-white" *ngIf="node.head">
                    <tr>
                        <th class="px-2 py-3"> Restaurant Name </th>
                        <th class="px-2 py-3"> Total Amount </th>
                        <th class="px-2 py-3"> Total Order </th>
                        <th class="px-2 py-3"> Paid Amount </th>
                        <th class="px-2 py-3"> Comission Amount </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="px-2 py-3"> {{node.restaurant_name}} </td>
                        <td class="px-2 py-3"> {{node.total_amount | currency: '$'}} </td>
                        <td class="px-2 py-3"> {{node.total_order}} </td>
                        <td class="px-2 py-3"> {{node.paid_amount | currency: '$'}} </td>
                        <td class="px-2 py-3 text-success"> {{node.comission_amount | currency: '$'}} </td>
                    </tr>
                </tbody>
            </table>
            <table class="table" *ngSwitchCase="3">
                <thead class="bg-primary text-white" *ngIf="node.head">
                    <tr>
                        <th class="px-2 py-3"> Order Amount </th>
                        <th class="px-2 py-3"> Order Date </th>
                        <th class="px-2 py-3"> Order Item Count </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="px-2 py-3"> {{node.order_amount}} </td>
                        <td class="px-2 py-3"> {{node.order_date}} </td>
                        <td class="px-2 py-3"> {{node.children.length}} </td>
                    </tr>
                </tbody>
            </table>
            <table class="table" *ngSwitchCase="4">
                <thead class="bg-primary text-white" *ngIf="node.head">
                    <tr>
                        <th class="px-2 py-3"> Menu Name </th>
                        <th class="px-2 py-3"> Price </th>
                        <th class="px-2 py-3"> Quantity </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="px-2 py-3"> {{node.menu_name}} </td>
                        <td class="px-2 py-3"> {{node.price |  currency : '$'}} </td>
                        <td class="px-2 py-3"> {{node.qty}} </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
            <ng-container matTreeNodeOutlet></ng-container>
        </div>
    </mat-nested-tree-node>
</mat-tree> -->

<ngx-spinner
  [fullScreen]="true"
  bdColor="#fff"
  size="medium"
  color="#050a4f"
  type="ball-clip-rotate-multiple"
>
  <p style="font-size: 20px; color: #050a4f">{{ spinnerText }}</p>
</ngx-spinner>

<div class="container-fluid">
  <div class="mx-0 my-2 row align-items-center justify-content-between">
    <h2 class="m-0 col-sm-12 col-md-5">Payment History</h2>
    <form
      class="m-0 col-sm-12 col-md-7"
      name="paymentFilterForm"
      [formGroup]="paymentFilterForm"
      (ngSubmit)="getPaymentHisoty()"
    >
      <div class="row justify-content-end">
        <div class="col-sm-12 col-md-6" [ngClass]="{ 'd-none': userRole != 1 }">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Store </mat-label>
            <mat-select
              formControlName="restaurant"
              (selectionChange)="getPaymentHisoty()"
            >
              <input
                (keyup)="search($event.target.value)"
                class="form-control w-100"
                placeholder="Search Restaurant"
              />
              <mat-option value="0">ALL</mat-option>
              <mat-option
                *ngFor="let rest of filteredRestaurantList"
                [value]="rest.id"
                >{{ rest.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>From</mat-label>
            <input
              (dateChange)="getPaymentHisoty()"
              readonly
              formControlName="from"
              matInput
              [matDatepicker]="picker"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-3">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>To</mat-label>
            <input
              (dateChange)="getPaymentHisoty()"
              readonly
              formControlName="to"
              matInput
              [matDatepicker]="to"
            />
            <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
            <mat-datepicker #to></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div class="table-responsive">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z8 w-100"
    >
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno</th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ page.pageIndex * page.pageSize + i + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.date | date : "MMM d, y" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="restaurant" *ngIf="userRole == 1">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Store</th>
        <td mat-cell *matCellDef="let element">
          <!-- <span *ngIf="element?.restaurant?.name?.length > 10"
              title="{{element?.restaurant?.name}}">{{element?.restaurant?.name?.substr(0,10)}}...</span>
            <span *ngIf="element?.restaurant?.name?.length < 10 ">{{element?.restaurant?.name}}</span> -->
          {{ element?.restaurant?.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="mobile" *ngIf="userRole == 1">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.restaurant?.info_mobile }}
        </td>
      </ng-container>

      <ng-container matColumnDef="orders">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Orders</th>
        <td mat-cell *matCellDef="let element">{{ element?.orders }}</td>
      </ng-container>

      <ng-container matColumnDef="commission">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Commission</th>
        <td mat-cell *matCellDef="let element" class="text-success">
          {{ element.commission || 0 | currency : "INR" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="tax">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tax</th>
        <td mat-cell *matCellDef="let element" class="text-secondary">
          {{ element.tax || 0 | currency : "INR" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="discount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Discount</th>
        <td mat-cell *matCellDef="let element" class="text-primary">
          {{ element.discount || 0 | currency : "INR" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="order_total">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Total</th>
        <td mat-cell *matCellDef="let element">
          {{ element.order_total || 0 | currency : "INR" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [colspan]="userRole == 1 ? 9 : 7">
          No data matching
        </td>
      </tr>
    </table>
  </div>

  <mat-paginator
    [pageSize]="10"
    [pageIndex]="page.pageIndex"
    [length]="page.length"
    (page)="customePaginator($event)"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
