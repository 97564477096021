<div class="table-responsive">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    class="mat-elevation-z8 w-100"
  >
    <ng-container matColumnDef="sno">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{
          menuPriceHistoryPage.pageIndex * menuPriceHistoryPage.pageSize + i + 1
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="menu">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
      <td mat-cell *matCellDef="let element">
        <span
          *ngIf="element?.menu_price?.menu?.name?.length > 15"
          title="{{ element?.store_item_variant?.item_variant?.item?.name }}"
          >{{
            element?.store_item_variant?.item_variant?.item?.name?.substr(0, 15)
          }}
          ...
        </span>
        <span
          *ngIf="element?.menu_price?.menu?.name?.length <= 15"
          title="{{ element?.store_item_variant?.item_variant?.item?.name }}"
          >{{ element?.store_item_variant?.item_variant?.item?.name }}</span
        >
      </td>
    </ng-container>

    <ng-container matColumnDef="unit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Unit</th>
      <td mat-cell *matCellDef="let element">
        {{
          element?.store_item_variant?.item_variant?.desc +
            " " +
            element?.store_item_variant?.item_variant?.unit
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
      <td mat-cell *matCellDef="let element" class="text-danger">
        {{ element.price | currency : "INR" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="updated_price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated Price</th>
      <td mat-cell *matCellDef="let element" class="text-success">
        {{ element.updated_price | currency : "INR" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="updated_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated At</th>
      <td mat-cell *matCellDef="let element">
        {{ element.updated_at | date : "dd-MM-yyyy hh:mm a" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
      <td mat-cell *matCellDef="let element">{{ element.user.name }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell text-center" colspan="12">
        {{
          language && language.hasOwnProperty("No data matching")
            ? language[
                "No
        data matching"
              ]
            : "No data matching"
        }}
      </td>
    </tr>
  </table>
</div>

<mat-paginator
  class="basic-paginataor"
  [pageSize]="menuPriceHistoryPage.pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="customePaginator($event, 'menu')"
  showFirstLastButtons
>
</mat-paginator>
