<div class="table-responsive">
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    class="mat-elevation-z8 w-100"
  >
    <ng-container matColumnDef="sno">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ page.pageIndex * page.pageSize + i + 1 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let element">{{ element?.name }}</td>
    </ng-container>

    <ng-container matColumnDef="image">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Picture</th>
      <td mat-cell *matCellDef="let element">
        <img
          [src]="apiUrl + '/' + element?.image || defaultImg"
          [alt]="element?.image"
          class="w-50px my-1"
          *ngIf="element?.image"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="mob_picture">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Mob Img</th>
      <td
        mat-cell
        *matCellDef="let element"
        style="width: 0"
        [ngClass]="{
          'text-success': element?.mob_picture,
          'text-danger': !element?.mob_picture
        }"
      >
        {{ element?.mob_picture ? "Y" : "N" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="is_active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td
        mat-cell
        *matCellDef="let element"
        [ngClass]="{
          'text-success': element?.is_active,
          'text-danger': !element?.is_active
        }"
      >
        {{ element?.is_active ? "Active" : "Deactivated" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="action" *ngIf="appService?.getUserRole() == 1">
      <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
      <td mat-cell *matCellDef="let element; index as i" class="text-center">
        <button mat-flat-button (click)="editBrand.emit(element)">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
              fill="white"
            />
          </svg>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching</td>
    </tr>
  </table>
</div>
