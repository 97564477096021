import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RestaurantService } from 'src/app/admin/restaurant/restaurant.service';

@Component({
  selector: 'app-plan-dialog',
  templateUrl: './plan-dialog.component.html',
  styleUrls: ['./plan-dialog.component.scss'],
})
export class PlanDialogComponent implements OnInit {
  planDetails: any = [];

  constructor(
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    // private deviceService: DeviceDetectorService,
    private api: RestaurantService
  ) {}

  ngOnInit(): void {
    const data = {};
    this.api.getPlans(data).subscribe((res: any) => {
      console.log(res);
      this.planDetails = res.data;
    });
  }
}
