<ngx-spinner
  [fullScreen]="true"
  bdColor="#fff"
  size="medium"
  color="#050a4f"
  type="ball-clip-rotate-multiple"
>
</ngx-spinner>
<div class="container-fluid">
  <h3>Searched Products By User</h3>

  <div class="table-responsive">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z8 w-100"
    >
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno</th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ page?.pageIndex * categoryPage?.pageSize + i + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
        <td mat-cell *matCellDef="let element">{{ element?.item?.name }}</td>
      </ng-container>

      <ng-container matColumnDef="picture">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Picture</th>
        <td mat-cell *matCellDef="let element">
          <img
            [src]="apiUrl + '/' + element?.item?.picture"
            [alt]="element?.item?.picture"
            class="w-50px"
            *ngIf="element?.item?.picture"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="brand">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.item?.brand?.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.item?.category?.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="sub_category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub Category</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.item?.sub_category?.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
        <td mat-cell *matCellDef="let element">{{ element?.user?.name }}</td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
        <td mat-cell *matCellDef="let element">
          +{{ element?.user?.dial_code + element?.user?.phone }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element">
          <button
            class="btn"
            (click)="
              addProductToStore(
                element?.item_id,
                element?.item?.name,
                element?.item?.posssible_variants
              )
            "
          >
            Get Product To Store
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="8">No Record Found</td>
      </tr>
    </table>
  </div>

  <mat-paginator
    [length]="page.length"
    [pageIndex]="page.pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    (page)="customePaginator($event)"
    class="basic-paginataor"
    showFirstLastButtons
  >
  </mat-paginator>
</div>

<ng-template #productVariants>
  <div>
    <div class="h-8 d-flex justify-content-between mb-2">
      <h3 class="m-0">{{ productName }} Variants</h3>
      <button
        class="btn bg-danger border-danger rounded-corner p-0"
        (click)="closeVariantDialog()"
      >
        <i class="fa fa-times text-white" aria-hidden="true"></i>
      </button>
    </div>
    <div class="mt-4">
      <div class="d-flex justify-content-end mb-3">
        <button
          class="btn"
          (click)="openProductVariantForm(productVariantForm)"
        >
          Create Variant
        </button>
      </div>
      <app-product-variant-list
        (editProductVariant)="
          openProductVariantForm(productVariantForm, $event)
        "
        [showActionColumn]="false"
        [fromPage]="'Req Products By User'"
        [productId]="productId"
        (openProductList)="getReqProdByUser()"
        [showActionColumn]="true"
      >
      </app-product-variant-list>
    </div>
  </div>
</ng-template>

<ng-template #productVariantForm>
  <div>
    <div class="h-8 d-flex justify-content-between mb-2">
      <h3 class="m-0">{{ productName }} Variants</h3>
      <button
        class="btn bg-danger border-danger rounded-corner p-0"
        mat-dialog-close
        #menuDialogclose
      >
        <i class="fa fa-times text-white" aria-hidden="true"></i>
      </button>
    </div>
    <div class="mt-4">
      <app-product-variant-form
        (closePopup)="closeProductVariantForm()"
        [posssible_variants]="possibleVariants"
        [productId]="productId"
        [setProductVariantId]="productVariantId"
      >
      </app-product-variant-form>
    </div>
  </div>
</ng-template>
