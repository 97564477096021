import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import * as $ from 'jquery';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'order2Pay';
  isLogin: string;
  showProfile: boolean = false;
  isMenuOpen: boolean = false;
  isMobMenuActive = false;
  curDate: any;
  user: any;
  endDate: any;
  ed: any;
  cd: any;

  logoutTimer: any;

  constructor(private router: Router, private appService: AppService) {}

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));

    var today = new Date(localStorage.getItem('current_date'));
    var year = today.getFullYear();
    var mes = today.getMonth() + 1;
    var dia = today.getDate();
    // this.curDate = year + '-' + mes + '-' + dia;
    this.curDate = year + '-' + mes + '-' + dia;

    var today1 = new Date(this.user?.store?.store_plan?.end_date);
    var year1 = today1.getFullYear();
    var mes1 = today1.getMonth() + 1;
    var dia1 = today1.getDate();
    this.endDate = year1 + '-' + mes1 + '-' + dia1;
    this.ed = new Date(this.endDate);
    this.cd = new Date(this.curDate);
    console.log(this.ed >= this.cd);
    console.log(this.ed <= this.cd);
    // console.log(this.endDate, this.curDate);

    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.isLogin = localStorage.getItem('token');
        // console.log(this.user);
        // console.log(this.user?.store?.store_plan?.end_date);
        this.resetLogoutTimer();
        // console.log(this.user.role == 1);

        if (this.isLogin) {
          this.showProfile = true;
        } else {
          this.showProfile = false;
        }
        // if (this.user.role == 1) {
        //   // console.log('role1', this.isLogin);

        // } else {
        //   if (this.endDate >= this.curDate) {
        //     // this.isLogin = localStorage.getItem('token');
        //     console.log('role2', this.isLogin);
        //     if (this.isLogin) {
        //       this.showProfile = true;
        //     } else {
        //       this.showProfile = false;
        //     }
        // }
      }
    });

    this.startLogoutTimer();
    this.resetLogoutTimer();
  }

  startLogoutTimer() {
    // console.log('timer started');
    // console.log(this.logoutUser);

    this.logoutTimer = setTimeout(this.logoutUser, 900000); // 15 minutes in milliseconds
    // console.log(this.logoutTimer);
  }

  resetLogoutTimer() {
    clearTimeout(this.logoutTimer);
    this.startLogoutTimer();
    // console.log('reset');
  }

  logoutUser() {
    // Make an HTTP request to the server to log out the user and redirect them to the login page
    // ...
    localStorage.clear();
    window.location.href = '/login';
  }

  onMenuTogglerChange(isMenuOpenStatus: boolean): void {
    this.isMenuOpen = isMenuOpenStatus ? false : true;
  }

  // sidebar menu change event function
  onMobMenuChange(isMobMenuOpenStatus: boolean): void {
    this.isMobMenuActive = isMobMenuOpenStatus ? false : true;
  }

  // close sidebar mobile menu layer
  closeMobMenu(): void {
    this.isMobMenuActive = !this.isMobMenuActive;
    this.isMenuOpen = false;
  }

  createRoles() {
    this.appService.createRoles().subscribe(
      (res: any) => {
        console.log('res', res);
      },
      (err: any) => {
        console.log('err', err);
      }
    );
  }
}
