import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  category: any = null;
  url: string = '';
  constructor(private http: HttpClient) {
    this.url = environment.API_URL;
  }

  setCategory(category: number) {
    this.category = category;
  }

  getCategory() {
    return this.category;
  }

  createUpdateCategory(data: any) {
    return this.http.post(`${this.url}/v1/categories/createUpdate`, data).pipe(catchError(this.errorHandler));
  }

  findAllCatgegories(data: any) {
    return this.http.get(`${this.url}/v1/categories/findAll`, { params: data }).pipe(catchError(this.errorHandler));
  }

  updateGlobalCategory(data: any) {
    return this.http.post(`${this.url}/v1/categories/updateGlobalCategory`, data).pipe(catchError(this.errorHandler));
  }

  findCategoryWithTargetStore(data: any) {
    return this.http.get(`${this.url}/v1/categories/findCategoryWithTargetStore`, { params: data }).pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.error || "server error.");
  }

}

