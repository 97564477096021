<div class="table-responsive">
  <table
    mat-table
    [dataSource]="variantDataSource"
    matSort
    class="mat-elevation-z8 w-100"
  >
    <ng-container
      matColumnDef="selected"
      *ngIf="appService?.getUserRole() != 1"
    >
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="selectAll($event)"
          [(ngModel)]="selectedAllVariant"
        ></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element" class="pr-3">
        <mat-checkbox
          (change)="updateAllCheckbox()"
          [(ngModel)]="element.selected"
        ></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="sno">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ VariantPage.pageIndex * VariantPage.pageSize + i + 1 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="unit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Unit</th>
      <td mat-cell *matCellDef="let element">
        {{ element?.desc + " " + element?.unit }}
      </td>
    </ng-container>

    <ng-container matColumnDef="limit" *ngIf="appService?.getUserRole() != 1">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Limit</th>
      <td mat-cell *matCellDef="let element">
        {{
          element?.store_item_variants?.length
            ? element?.store_item_variants[0].limit
            : ""
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="price" *ngIf="appService?.getUserRole() != 1">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Price</th>
      <td mat-cell *matCellDef="let element">
        {{
          element?.store_item_variants?.length
            ? (element?.store_item_variants[0].price | currency : "INR")
            : ""
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="taxable" *ngIf="appService?.getUserRole() != 1">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Taxable</th>
      <td mat-cell *matCellDef="let element">
        {{
          element?.store_item_variants?.length
            ? element?.store_item_variants[0].taxable
              ? "Yes"
              : "No"
            : ""
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status" *ngIf="appService?.getUserRole() != 1">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td
        mat-cell
        *matCellDef="let element"
        [ngClass]="{
          'text-success': element?.status === 'Activated',
          'text-danger': element?.status !== 'Activated'
        }"
      >
        {{ element?.status }}
      </td>
    </ng-container>

    <ng-container matColumnDef="picture">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Picture</th>
      <td mat-cell *matCellDef="let element">
        <img
          [src]="apiUrl + '/' + element?.picture"
          [alt]="element?.picture"
          class="w-50px rounded"
          *ngIf="element?.picture"
        />
      </td>
    </ng-container>

    <ng-container matColumnDef="action" *ngIf="showActionColumn">
      <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
      <td mat-cell *matCellDef="let element; index as i" class="text-center">
        <button
          class="btn"
          *ngIf="element.synced || appService.getUserRole() == 1"
          (click)="editProductVariant.emit(element.id)"
        >
          <i class="fa fa-edit text-white"></i>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="variantColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: variantColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching</td>
    </tr>
  </table>
</div>

<div
  class="d-flex justify-content-end mt-4 w-100"
  *ngIf="fromPage !== 'product'"
>
  <button class="btn btn-success" (click)="updateGlobalList()">Update</button>
</div>
