<div class="container-fluid">
  <div class="my-2 d-flex align-items-center justify-content-between">
    <h2>Help List</h2>
    <a
      class="bg-success text-white"
      mat-flat-button
      routerLink="/admin/helpdesk-form"
      *ngIf="userRole != 1"
      >Ask Help
    </a>
  </div>

  <div class="table-responsive">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z8 w-100"
    >
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno</th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ page.pageIndex * page.pageSize + i + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="issue">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Issue</th>
        <td mat-cell *matCellDef="let element">{{ element.issue_name }}</td>
      </ng-container>

      <ng-container matColumnDef="desc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let element">{{ element.desc }}</td>
      </ng-container>

      <ng-container matColumnDef="priority">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>priority</th>
        <td mat-cell *matCellDef="let element">{{ element.priority_name }}</td>
      </ng-container>

      <ng-container matColumnDef="restaurant">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Store</th>
        <td mat-cell *matCellDef="let element">{{ element.restaurant }}</td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.created_by_user }}
        </td>
      </ng-container>

      <ng-container matColumnDef="picture">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Picture</th>
        <td mat-cell *matCellDef="let element" class="w-0">
          <img
            [src]="element.picture"
            [alt]="element.picture_name"
            class="w-100"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
        <td mat-cell *matCellDef="let element">
          {{ element.created_at | date : "dd-MM-yyyy hh:mm:ss" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'text-success': !element.status,
            'text-danger': element.status
          }"
        >
          {{ element.status ? "Open" : "Closed" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
        <td mat-cell *matCellDef="let element; index as i" class="text-center">
          <button
            mat-flat-button
            (click)="editHelp(element.id)"
            [disabled]="userRole == 1"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                fill="white"
              />
            </svg>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="12" style="text-align: center">
          {{
            language && language.hasOwnProperty("No data matching")
              ? language[
                  "No
                    data matching"
                ]
              : "No data matching"
          }}
        </td>
      </tr>
    </table>
  </div>

  <mat-paginator
    [length]="page.length"
    [pageIndex]="page.pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    (page)="customePaginator($event)"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
