<ngx-spinner
  [fullScreen]="true"
  bdColor="#fff"
  size="medium"
  color="#050a4f"
  type="ball-clip-rotate-multiple"
>
</ngx-spinner>
<div class="container-fluid">
  <div class="my-2 d-flex align-items-center justify-content-between">
    <h2>User List</h2>
    <a class="bg-success text-white" mat-flat-button (click)="editUser(null)"
      >Create User
    </a>
  </div>

  <div class="table-responsive">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z8 w-100"
    >
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno</th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ page?.pageIndex * page?.pageSize + i + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element">{{ element?.name }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let element">{{ element?.email }}</td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.dial_code + element?.phone }}
        </td>
      </ng-container>

      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.created_at | date : "dd-MM-yyyy" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.is_active ? "Active" : "Deactivated" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.user_role ? element?.user_role?.name : "" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="picture">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Picture</th>
        <td mat-cell *matCellDef="let element">
          <img
            [src]="apiUrl + '/' + element?.picture"
            alt="user-image"
            class="w-60px"
            *ngIf="element?.picture"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
        <td mat-cell *matCellDef="let element; index as i" class="text-center">
          <button
            mat-flat-button
            (click)="editUser(element)"
            [disabled]="
              element?.user_role &&
              (element?.user_role?.id == 2 || element?.user_role?.id == 5)
            "
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                fill="white"
              />
            </svg>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          {{
            language && language.hasOwnProperty("No data matching")
              ? language["No data matching"]
              : "No data matching"
          }}
        </td>
      </tr>
    </table>
  </div>

  <mat-paginator
    [length]="page.length"
    [pageIndex]="page.pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    (page)="customePaginator($event)"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
