<ngx-spinner
  [fullScreen]="true"
  bdColor="#fff"
  size="medium"
  color="#050a4f"
  type="ball-clip-rotate-multiple"
>
  <p style="font-size: 20px; color: #050a4f">{{ spinnerText }}</p>
</ngx-spinner>

<div class="container-fluid">
  <h2 class="w-100 mb-3">Transaction History</h2>
  <div class="mx-0 my-2 row align-items-center justify-content-between">
    <div class="col-sm-12 col-md-6 p-0">
      <mat-form-field appearance="outline" class="w-75">
        <mat-label>Search</mat-label>
        <input
          matInput
          placeholder="Search"
          (keyup)="applyDataTableFilter($event?.target?.value)"
          #input
        />
      </mat-form-field>
    </div>
    <form
      class="m-0 col-sm-12 col-md-6 p-0"
      name="paymentFilterForm"
      [formGroup]="paymentFilterForm"
      (ngSubmit)="getPaymentHisoty()"
    >
      <div class="row justify-content-end">
        <div class="col-sm-12 col-md-4" [ngClass]="{ 'd-none': userRole != 1 }">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Store </mat-label>
            <mat-select
              multiple
              formControlName="store_id"
              (selectionChange)="getPaymentHisoty()"
            >
              <input
                (keyup)="search($event.target.value)"
                class="form-control w-100"
                placeholder="Search Store"
              />
              <mat-option
                *ngFor="let rest of filteredRestaurantList"
                [value]="rest.id"
                >{{ rest.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>From</mat-label>
            <input
              (dateChange)="getPaymentHisoty()"
              readonly
              formControlName="from"
              matInput
              [matDatepicker]="picker"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>To</mat-label>
            <input
              (dateChange)="getPaymentHisoty()"
              readonly
              formControlName="to"
              matInput
              [matDatepicker]="to"
            />
            <mat-datepicker-toggle matSuffix [for]="to"></mat-datepicker-toggle>
            <mat-datepicker #to></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
  <div class="table-responsive">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z8 w-100"
    >
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-6">
          Sno
        </th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ page.pageIndex * page.pageSize + i + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.date || element?.created_at | date : "MMM d, y" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="user" class="w-8">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.user?.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="store" *ngIf="userRole == 1">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Store</th>
        <td mat-cell *matCellDef="let element">
          <app-text-ellipsis
            [length]="20"
            [text]="
              element?.order_items?.length
                ? element?.order_items[0]?.store?.name
                : ''
            "
          ></app-text-ellipsis>
        </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
        <td mat-cell *matCellDef="let element">
          +{{ element?.user?.dial_code + element?.user?.phone }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="orders"
        *ngIf="paymentFilterForm.value.payment_status != 2"
      >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Id</th>
        <td mat-cell *matCellDef="let element">{{ element?.order_id }}</td>
      </ng-container>

      <ng-container matColumnDef="commission">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Commission</th>
        <td mat-cell *matCellDef="let element" class="text-success">
          {{ element?.commission | currency : "INR" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="discount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Discount</th>
        <td mat-cell *matCellDef="let element" class="text-success">
          {{ element?.discount | currency : "INR" }}
        </td>
      </ng-container>

      <ng-container
        matColumnDef="tax"
        *ngIf="paymentFilterForm.value.payment_status != 2"
      >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tax</th>
        <td mat-cell *matCellDef="let element" class="text-danger">
          {{ element.tax || 0 | currency : "INR" }}
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="discount">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Discount</th>
              <td mat-cell *matCellDef="let element" class="text-primary"> {{(element.discount || 0) | currency : '$'}} </td>
          </ng-container> -->

      <ng-container
        matColumnDef="order_total"
        *ngIf="paymentFilterForm.value.payment_status != 2"
      >
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Total</th>
        <td mat-cell *matCellDef="let element" class="text-success">
          {{ element.order_total || 0 | currency : "INR" }}
        </td>
      </ng-container>

      <!-- <ng-container matColumnDef="action" *ngIf="paymentFilterForm.value.payment_status != 2">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Log</th>
        <td mat-cell *matCellDef="let element" class="text-success">
          <i class="fa fa-eye py-2 px-3 bg-primary text-white" (click)="openConfirmationDialog(element.retref)"></i>
          <button
            class="btn btn-sucess"
            (click)="openConfirmationDialog(element.retref)"
          >
            Log
          </button>
        </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="userRole == 1 ? 10 : 9">
          No data matching
        </td>
      </tr>
    </table>
  </div>

  <mat-paginator
    [pageSize]="10"
    [pageIndex]="page.pageIndex"
    [length]="page.length"
    (page)="customePaginator($event)"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  >
  </mat-paginator>
</div>

<ng-template #TransactionModal>
  <div class="d-flex justify-content-between align-items-center mb-3">
    <h3 class="text-color">Transaction Log</h3>
    <i class="fa fa-close py-2 px-3 bg-danger text-white" mat-dialog-close></i>
    <!-- <button mat-flat-button mat-dialog-close>Close</button> -->
  </div>
  <div class="order-log-container">
    <table
      class="table table-hover table-layout-fixed"
      *ngIf="transactionLogData?.length"
    >
      <thead>
        <tr class="bg-info text-white">
          <th>Type</th>
          <th>Code</th>
          <th>Response</th>
          <th>Created Time</th>
          <th>Batch ID</th>
          <th class="w-200px">Actual Request</th>
          <th class="w-200px">Actual Response</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of transactionLogData">
          <td class="text-bold">{{ data?.api }}</td>
          <td class="text-left">
            {{ returnJson(data?.response, "respcode") }}
          </td>
          <td class="text-left">
            {{ returnJson(data?.response, "resptext") }}
          </td>
          <td class="text-left">
            {{ data?.created_at | date : "dd-MM-yyyy hh:mm a" }}
          </td>
          <td>{{ returnJson(data?.response, "batchid") }}</td>
          <td class="json">
            <app-text-ellipsis
              [length]="20"
              [text]="data?.request"
            ></app-text-ellipsis>
          </td>
          <td class="json">
            <app-text-ellipsis
              [length]="20"
              [text]="data?.request"
            ></app-text-ellipsis>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
