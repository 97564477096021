import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ElementRef,
  TemplateRef,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';
import { ProductService } from '../product.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppService } from 'src/app/app.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CategoryService } from '../category.service';
import { SubcategoryService } from '../subcategory.service';
import { MatAccordion } from '@angular/material/expansion';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { BrandService } from '../brand.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  defaultImg = `${environment.API_URL}/uploads/images/placeholder.jpg`;
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  dataSource: any = new MatTableDataSource();
  tabName = '3';
  displayedColumns: string[] = [
    'sno',
    'name',
    'code',
    'category',
    'sub_category',
    'price',
    'limit',
    'quantity',
    'picture',
    'created_at',
    'status',
    'action',
  ];
  productList = [];
  pageSizeOptions = [5, 10, 20];
  pageEvent: PageEvent;
  menuPage = { length: 0, pageIndex: 0, pageSize: 5 };
  categoryPage = { length: 0, pageIndex: 0, pageSize: 5 };
  subCategoryPage = { length: 0, pageIndex: 0, pageSize: 5 };
  apiUrl: string = '';
  Product: any = {
    name: '',
    code: '',
    desc: '',
    category_id: '',
    sub_category_id: '',
    price: '',
    is_active: true,
    created_at: new Date(),
    store_id: null,
    picture: '',
    picture_name: '',
    has_limit: false,
    limit: '',
    id: null,
    vegetarian: '',
    taxable: '',
  };
  ProductForm = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(50),
    ]),
    code: new FormControl('', [
      Validators.required,
      Validators.minLength(1),
      Validators.maxLength(6),
    ]),
    desc: new FormControl('', [
      Validators.minLength(1),
      Validators.maxLength(200),
    ]),
    category: new FormControl('', [Validators.required]),
    sub_category: new FormControl('', [Validators.required]),
    price: new FormControl('', [Validators.required]),
    is_active: new FormControl(''),
    has_limit: new FormControl(''),
    limit: new FormControl(''),
    vegetarian: new FormControl(''),
    taxable: new FormControl(''),
  });
  image: any = {
    name: '',
    src: '',
    file: '',
  };
  categoryImage: any = {
    name: '',
    src: '',
    file: '',
  };
  subCategoryImage: any = {
    name: '',
    src: '',
    file: '',
  };
  userRole: number = 0;
  @ViewChild('file') file!: ElementRef;
  @ViewChild('categoryFile') categoryFile!: ElementRef;
  @ViewChild('subCategoryFile') subCategoryFile!: ElementRef;
  @ViewChild('input') fileInput!: ElementRef;

  filteredBrandDropList: any = [];
  brandDropList: any = [];
  categoryList: any = [];
  categoryDropList: any = [];
  filteredCategoryDropList: any = [];
  subCategoryList: any = [];
  subCategoryDropList: any = [];
  filteredSubCategoryDropList: any = [];
  @ViewChild(MatAccordion) accordion: MatAccordion;
  categoryDataSource: any = new MatTableDataSource();
  categoryColumns: string[] = ['sno', 'name', 'desc', 'picture', 'mob_picture'];
  refreshChildMenuData: boolean = false;
  refreshProductVariantList: boolean = false;
  formObj = {
    category: false,
    subcategory: false,
    menuForm: false,
    brand: false,
    menuList: false,
  };
  Category: any = {
    picture: '',
    picture_name: '',
    name: '',
    desc: '',
    is_active: true,
    created_at: '',
    id: null,
  };
  CategoryForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    desc: new FormControl(''),
    is_active: new FormControl(''),
  });
  Subcategory: any = {
    picture: '',
    picture_name: '',
    name: '',
    desc: '',
    category_id: '',
    is_active: true,
    created_at: '',
    store_id: null,
    id: null,
  };
  SubcategoryForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    desc: new FormControl(''),
    category: new FormControl('', [Validators.required]),
    is_active: new FormControl(''),
  });
  subCategoryDataSource: any = new MatTableDataSource();
  subCategoryColumns: string[] = [
    'sno',
    'name',
    'category',
    'desc',
    'picture',
    'mob_picture',
  ];
  menupanelListOpenState: boolean = false;
  menupanelFormOpenState: boolean = false;
  subCateogrypanelFormOpenState: boolean = false;
  subCateogrypanelListOpenState: boolean = false;
  cateogrypanelFormOpenState: boolean = false;
  cateogrypanelListOpenState: boolean = false;
  formDirective: any;
  excelData: any = [];
  menuMasterData: any = [];
  disableCategorySubmit: boolean = false;
  disableSubCategorySubmit: boolean = false;
  disableMenuSubmit: boolean = false;
  store_id: any;
  menuImagePath: string;
  selectedProductId: number;
  public selectedProductName!: string;
  @ViewChild('productVariants') productVariants: TemplateRef<any>;
  brand: any;
  loadBrandList: boolean;
  brandDropOpened: boolean = false;
  categoryDropOpened: boolean = false;
  subCategoryDropOpened: boolean = false;
  productFilter = {
    category_id: null,
    sub_category_id: null,
    brand_id: null,
  };
  selectedPossibleVariants: any;
  selectedProductVariantId: number;
  public searchString!: string;
  public itemCountConfig!: any;
  private searchSub!: Subject<string>;

  constructor(
    private productService: ProductService,
    private router: Router,
    private toastr: ToastrService,
    private appService: AppService,
    private categoryService: CategoryService,
    private subcategoryService: SubcategoryService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private brandService: BrandService
  ) {}

  ngOnInit(): void {
    this.store_id = this.appService.getRestaurantId();
    // this.getMenuList();
    this.apiUrl = environment.API_URL;
    this.userRole = this.appService.getUserRole();
    this.getCategoryList();
    // this.getCategoryDropList();
    // this.getSubCategoryList();
    // this.fetchMenuMasterData();
    if (this.userRole == 1) {
      this.subCategoryColumns.push('is_active', 'action');
      this.categoryColumns.push('is_active', 'action');
    }
    this.searchSub = new Subject();
    this.searchSub?.pipe(debounceTime(500))?.subscribe((res) => {
      if (this.tabName == '0' || this.tabName == '1') {
        this.filterRecords(res);
      } else {
        this.searchString = res;
      }
    });
  }

  uploadPicture(type: string) {
    if (type == 'menu') this.file.nativeElement.click();
    else if (type == 'category') this.categoryFile.nativeElement.click();
    else this.subCategoryFile.nativeElement.click();
  }

  readFile(event: any, type: string) {
    const file = event.target.files[0];
    if (type == 'menu') {
      this.image.name = file.name;
      this.image.file = file;

      const reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.image.src = reader.result as string;
        };
      }
    } else if (type == 'category') {
      this.categoryImage.name = file.name;
      this.categoryImage.file = file;

      const reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.categoryImage.src = reader.result as string;
        };
      }
    } else {
      this.subCategoryImage.name = file.name;
      this.subCategoryImage.file = file;

      const reader = new FileReader();
      if (event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.subCategoryImage.src = reader.result as string;
        };
      }
    }
  }

  getCategoryList() {
    const data = {
      store_id: this.appService.getRestaurantId(),
    };
    this.spinner.show();
    this.categoryService.findAllCatgegories(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.categoryList = res.data;
          this.categoryDataSource = new MatTableDataSource(this.categoryList);
          this.categoryPage.length = this.productList.length;
          this.getPaginator(this.categoryDataSource, 0);
        } else this.toastr.error(res.message, 'Error');
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    );
  }

  getCategoryDropList() {
    const data = { dropDown: true };
    this.spinner.show();
    this.categoryService.findAllCatgegories(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.categoryDropList = res.data;
          this.filteredCategoryDropList = [...this.categoryDropList];
        } else this.toastr.error(res.message, 'Error');
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    );
  }

  getMenuList() {
    const data = {
      from_web: true,
      store_id: this.appService.getRestaurantId(),
      category_id: this.productFilter.category_id,
      sub_category_id: this.productFilter.sub_category_id,
    };
    this.spinner.show();
    this.productService.findAllMenus(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.productList = res.data;
          this.dataSource = new MatTableDataSource(this.productList);
          this.menuPage.length = this.productList.length;
          this.getPaginator(this.dataSource, 2);
        } else this.toastr.error(res.message, 'Error');
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    );
  }

  editMenu(element: any) {
    this.formObj.menuForm = true;
    element.price = element.menu_price.price;
    Object.assign(this.Product, element);
    this.image.src = this.Product.picture
      ? `${this.apiUrl}/${this.Product.picture}`
      : '';
    this.menuImagePath = this.image.src;
    this.menupanelFormOpenState = true;
    this.getSubCategoryDropList();
  }

  customePaginator(event, type: string) {
    if (type == 'menu') {
      this.menuPage.pageIndex = event.pageIndex;
      this.menuPage.pageSize = event.pageSize;
    } else if (type == 'category') {
      this.categoryPage.pageIndex = event.pageIndex;
      this.categoryPage.pageSize = event.pageSize;
    } else {
      this.subCategoryPage.pageIndex = event.pageIndex;
      this.subCategoryPage.pageSize = event.pageSize;
    }
  }

  getSubCategoryDropList(category?) {
    const data = {
      store_id: this.appService.getRestaurantId(),
      category_id: category ? category : this.Product.category_id,
    };
    this.spinner.show();
    this.subcategoryService.findAllSubCatgegories(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.subCategoryDropList = res.data;
          this.filteredSubCategoryDropList = [...this.subCategoryDropList];
        } else this.toastr.error(res.message, 'Error');
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    );
  }

  getSubCategoryList() {
    const data = {
      store_id: this.appService.getRestaurantId(),
      category_id: this.Product.category_id,
    };
    this.spinner.show();
    this.subcategoryService.findAllSubCatgegories(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.subCategoryList = res.data;
          this.subCategoryDataSource = new MatTableDataSource(
            this.subCategoryList
          );
          this.subCategoryPage.length = this.productList.length;
          this.getPaginator(this.subCategoryDataSource, 1);
        } else this.toastr.error(res.message, 'Error');
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    );
  }

  applyFilter(value: string): void {
    this.searchSub.next(value);
  }

  private filterRecords(str: string) {
    const trimmeredVal = str?.trim()?.toLowerCase();
    let filtered = [];
    if (this.tabName == '1') {
      filtered = [
        ...this.subCategoryList?.filter(
          (val) =>
            val?.name?.toLowerCase()?.includes(trimmeredVal) ||
            val?.desc?.toLowerCase()?.includes(trimmeredVal) ||
            val?.category?.name?.toLowerCase()?.includes(trimmeredVal)
        ),
      ];
      this.subCategoryDataSource = new MatTableDataSource(filtered);
      this.subCategoryPage.length = filtered?.length;
    } else {
      filtered = [
        ...this.categoryList?.filter(
          (val) =>
            val?.name?.toLowerCase()?.includes(trimmeredVal) ||
            val?.desc?.toLowerCase()?.includes(trimmeredVal)
        ),
      ];
      this.categoryDataSource = new MatTableDataSource(filtered);
      this.categoryPage.length = filtered?.length;
    }
  }

  search(value: string, type: string) {
    // console.log(type, value);

    if (type == 'brand') {
      // console.log(type);

      this.filteredBrandDropList = this.brandDropList.filter((state) => {
        let lowerCase = state.name.toLocaleLowerCase();
        return state.name.startsWith(value) || lowerCase.startsWith(value);
      });
    } else if (type == 'category') {
      this.filteredCategoryDropList = this.categoryDropList.filter((state) => {
        let lowerCase = state.name.toLocaleLowerCase();
        return state.name.startsWith(value) || lowerCase.startsWith(value);
      });
    } else {
      this.filteredSubCategoryDropList = this.subCategoryDropList.filter(
        (city) => {
          let lowerCase = city.name.toLocaleLowerCase();
          return city.name.startsWith(value) || lowerCase.startsWith(value);
        }
      );
    }
  }

  onSubmit(formDirective: any) {
    if (this.ProductForm.invalid) return;
    else {
      this.disableMenuSubmit = true;
      this.formDirective = formDirective;
      this.Product.store_id = this.appService.getRestaurantId();
      const formData = new FormData();
      formData.append('Image', this.image.file);
      formData.append('data', JSON.stringify(this.Product));
      this.spinner.show();
      this.productService.createUpdateMenu(formData).subscribe(
        (res: any) => {
          this.spinner.hide();
          this.formObj.menuForm = false;
          this.formObj.menuList = true;
          this.disableMenuSubmit = false;
          if (res.status) {
            this.toastr.success(res.message, 'Success');
            this.getMenuList();
            this.formDirective.resetForm();
            this.ProductForm.reset();
            this.image = { name: '', src: '', file: '' };
            this.menupanelListOpenState = true;
          } else this.toastr.error(res.message, 'Error');
        },
        (err) => {
          this.spinner.hide();
          this.formObj.menuForm = false;
          this.formObj.menuList = true;
          this.disableMenuSubmit = false;
          this.toastr.error(err.message, 'Error');
        }
      );
    }
  }

  editCategory(category: any) {
    this.formObj.category = true;
    this.Category = category;
    this.categoryImage.src = this.Category.picture
      ? `${this.apiUrl}/${this.Category.picture}`
      : '';
    this.cateogrypanelFormOpenState = true;
  }

  submitCategory(formDirective: any) {
    if (this.CategoryForm.invalid) return;
    else {
      this.disableCategorySubmit = true;
      this.formDirective = formDirective;
      const formData = new FormData();
      formData.append('Image', this.categoryImage.file);
      formData.append('data', JSON.stringify(this.Category));
      this.spinner.show();
      this.categoryService.createUpdateCategory(formData).subscribe(
        (res: any) => {
          this.formObj.category = false;
          this.disableCategorySubmit = false;
          this.spinner.hide();
          if (res.status) {
            this.toastr.success(res.message, 'Success');
            this.getCategoryList();
            this.getCategoryDropList();
            this.cateogrypanelListOpenState = true;
            this.formDirective.resetForm();
            this.CategoryForm.reset();
            this.categoryImage = { name: '', src: '', file: '' };
          } else this.toastr.error(res.message, 'Error');
        },
        (err) => {
          this.disableCategorySubmit = false;
          this.spinner.hide();
          this.toastr.error(err.message, 'Error');
        }
      );
    }
  }

  editSubCategory(subcategory: any) {
    this.formObj.subcategory = true;
    this.Subcategory = subcategory;
    this.Subcategory.category_id = subcategory?.category?.id;
    this.subCategoryImage.src = this.Subcategory.picture
      ? `${this.apiUrl}/${this.Subcategory.picture}`
      : '';
    this.subCateogrypanelFormOpenState = true;
  }

  submitSubCategory(formDirective: any) {
    if (this.SubcategoryForm.invalid) return;
    else {
      this.disableSubCategorySubmit = true;
      this.formDirective = formDirective;
      this.Subcategory.store_id = this.appService.getRestaurantId();
      const formData = new FormData();
      formData.append('Image', this.subCategoryImage.file);
      formData.append('data', JSON.stringify(this.Subcategory));
      this.spinner.show();
      this.subcategoryService.createUpdateSubCategory(formData).subscribe(
        (res: any) => {
          this.formObj.subcategory = false;
          this.disableSubCategorySubmit = false;
          this.spinner.hide();
          if (res.status) {
            this.toastr.success(res.message, 'Success');
            this.getSubCategoryList();
            this.getSubCategoryDropList();
            this.subCateogrypanelListOpenState = true;
            this.formDirective.resetForm();
            this.SubcategoryForm.reset();
            this.subCategoryImage = { name: '', src: '', file: '' };
          } else this.toastr.error(res.message, 'Error');
        },
        (err) => {
          this.formObj.subcategory = true;
          this.disableSubCategorySubmit = true;
          this.spinner.hide();
          this.toastr.error(err.message, 'Error');
        }
      );
    }
  }

  updateValidation() {
    if (this.Product.has_limit) {
      this.ProductForm.controls['limit'].setValidators([Validators.required]);
      this.ProductForm.controls['limit'].updateValueAndValidity();
    } else {
      this.ProductForm.controls['limit'].clearValidators();
      this.ProductForm.controls['limit'].updateValueAndValidity();
    }
  }

  getPaginator(dataSource: MatTableDataSource<any>, index: number): void {
    setTimeout(() => {
      dataSource.paginator = this.paginator.toArray()[index];
      dataSource.sort = this.sort.toArray()[index];
    }, 100);
  }

  onReadExcel(event: any) {
    const file = event.target.files[0];
    const fileName = file.name;
    let fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.onload = (e) => {
      let arrayBuffer: any = fileReader.result;
      var data = new Uint8Array(arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join('');
      var workbook = XLSX.read(bstr, { type: 'binary' });
      this.excelData = XLSX.utils.sheet_to_json(workbook.Sheets['Sheet1'], {
        raw: true,
      });
      if (this.excelData && this.excelData.length) this.validateSheet();
      else this.toastr.warning('No Data Found', 'Warning');
    };
  }

  validateSheet() {
    try {
      let menuMasterData: any = [];
      let categoryData: any = [];
      let subCategoryData: any = [];

      if (this.excelData && this.excelData.length) {
        let row = 1;
        for (let menu of this.excelData) {
          if (!menu['Menu Name']) {
            this.toastr.warning(`Menu Name not found on row ${row}`, 'Warning');
            return false;
          } else if (!menu['Price']) {
            this.toastr.warning(`Price not found on row ${row}`, 'Warning');
            return false;
          } else if (!menu['Category']) {
            this.toastr.warning(`Category not found on row ${row}`, 'Warning');
            return false;
          } else if (!menu['Sub Category']) {
            this.toastr.warning(
              `Sub Category not found on row ${row}`,
              'Warning'
            );
            return false;
          } else {
            const menuExists = this.menuMasterData.find(
              (me: any) => me.name == menu['Menu Name']
            );
            const category = this.categoryList.find(
              (cate: any) => cate.name == menu['Category']
            );
            const sub_category = this.subCategoryList.find(
              (subcate: any) => subcate.name == menu['Sub Category']
            );
            const limit = menu['Limit'] == 'Yes' || 'No' ? true : false;

            if (menuExists) {
              this.toastr.warning(
                `Menu ${menu['Menu Name']} already exists on ${row}`,
                'Warning'
              );
              return false;
            } else if (!limit) {
              this.toastr.warning(
                `Select a value for limit from the list on ${row}`,
                'Warning'
              );
              return false;
            } else {
              const userDetail = this.appService.getUserDetails();
              const menuData = {
                name: menu['Menu Name'],
                desc: menu['Description'],
                category_id: category ? category.id : 0,
                sub_category_id: sub_category ? sub_category.id : 0,
                menu_price: {
                  price: menu['Price'],
                  created_at: new Date(),
                  updated_at: new Date(),
                  created_by: userDetail.id,
                  updated_by: userDetail.id,
                  store_id: userDetail.store_id,
                },
                is_active: true,
                created_at: new Date(),
                updated_at: new Date(),
                created_by: userDetail.id,
                updated_by: userDetail.id,
                store_id: userDetail.store_id,
                has_limit: menu['Limit'] ? true : false,
                limit: menu['Limit'] ? menu['Limit'] : 0,
                quantity: menu['Limit'] ? menu['Limit'] : 0,
              };
              menuMasterData.push(menuData);
              if (!category)
                categoryData.push({
                  name: menu['Category'],
                  menuIndex: menuMasterData.length - 1,
                  created_at: new Date(),
                  updated_at: new Date(),
                  created_by: userDetail.id,
                  updated_by: userDetail.id,
                  store_id: userDetail.store_id,
                });
              if (!sub_category) {
                if (category)
                  subCategoryData.push({
                    name: menu['Sub Category'],
                    category: category,
                    menuIndex: menuMasterData.length - 1,
                    created_at: new Date(),
                    updated_at: new Date(),
                    created_by: userDetail.id,
                    updated_by: userDetail.id,
                    store_id: userDetail.store_id,
                  });
                else {
                  const categoryIndex = categoryData.findIndex(
                    (cate: any) => cate.name == menu['Category']
                  );
                  subCategoryData.push({
                    name: menu['Sub Category'],
                    categoryIndex: categoryIndex,
                    menuIndex: menuMasterData.length - 1,
                    created_at: new Date(),
                    updated_at: new Date(),
                    created_by: userDetail.id,
                    updated_by: userDetail.id,
                    store_id: userDetail.store_id,
                  });
                }
              }
            }
          }
          row++;
        }
        const masterData = {
          menuMasterData: menuMasterData,
          categoryData: categoryData,
          subCategoryData: subCategoryData,
        };
        this.createMenuMasterData(masterData);
      } else this.toastr.warning('No Data Found', 'Warning');
    } catch (err) {
      this.toastr.error(err, 'Error');
    }
  }

  fetchMenuMasterData() {
    this.spinner.show();
    this.productService.findMenuMasterData().subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) this.menuMasterData = res.data;
        else this.toastr.error(res.message, 'Error');
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    );
  }

  createMenuMasterData(menuData: any) {
    this.spinner.show();
    this.productService.createMenuMaster(menuData).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.toastr.success(res.message, 'Success');
          this.getMenuList();
          this.getCategoryList();
          this.getSubCategoryList();
        } else this.toastr.error(res.message, 'Error');
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    );
  }

  openFile() {
    this.fileInput.nativeElement.click();
  }

  cateForm() {
    this.formObj.category = !this.formObj.category;
  }

  cateSub() {
    this.formObj.subcategory = !this.formObj.subcategory;
  }

  CreateMenu() {
    this.formObj.menuForm = !this.formObj.menuForm;
    this.formObj.menuList = !this.formObj.menuList;
    if (this.formObj.menuList) this.refreshChildMenuData = true;
    this.Product = {
      is_active: true,
    };
    this.selectedProductId = null;
    this.selectedProductVariantId = null;
  }

  openTab(val) {
    this.tabName = val;
    if (val == '0') this.getCategoryList();
    else if (val == '1') {
      this.getSubCategoryList();
      this.getCategoryDropList();
    } else if (val == '2') {
      this.getBrandList();
      this.getCategoryDropList();
      this.formObj.menuList = true;
    } else this.loadBrandList = true;
  }

  getBrandList() {
    const data = {};
    this.spinner.show();
    this.brandService.findAllBrand(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.brandDropList = res.data;
          this.filteredBrandDropList = [...this.brandDropList];
        } else this.toastr.error(res.message, 'Error');
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    );
  }

  hideMenuForm(event) {
    if (event) {
      this.formObj.menuForm = false;
      this.formObj.menuList = true;
      this.refreshChildMenuData = true;
    }
  }

  setProductData(data: any) {
    this.selectedProductId = data?.item_id;
    this.selectedPossibleVariants = data?.posssible_variants;
    Object.assign(this.Product, data);
    // this.Product = data;
    this.formObj.menuForm = true;
    this.formObj.menuList = false;
    this.menuImagePath = this.Product?.picture;
  }

  openGlobalList(tempalte: any) {
    this.dialog.closeAll();
    this.dialog.open(tempalte, {
      width: '95vw !important',
      height: '95vh',
      minWidth: '95vw',
    });
  }

  openVariantList(value: any) {
    this.selectedProductId = value?.id;
    this.selectedProductName = value?.name;

    this.selectedPossibleVariants = value?.posssible_variants?.split(',');
    this.dialog.closeAll();
    this.dialog.open(this.productVariants, {
      width: '60vw !important',
      height: '60vh',
      minWidth: '60vw',
    });
  }

  editBrand(values) {
    this.formObj.brand = true;
    Object.assign(this.brand, values);
  }

  loadBrandData() {
    this.loadBrandList = true;
    this.formObj.brand = false;
  }

  openedChange(value) {
    if (value == 'brand') {
      this.brandDropOpened = !this.brandDropOpened;
      if (
        !this.brandDropOpened &&
        (this.productFilter.brand_id?.length ||
          this.productFilter.category_id?.length ||
          this.productFilter.sub_category_id?.length)
      )
        this.refreshChildMenuData = true;
    } else if (value == 'category') {
      this.categoryDropOpened = !this.categoryDropOpened;
      if (!this.categoryDropOpened && this.productFilter.category_id?.length) {
        this.getSubCategoryDropList(this.productFilter.category_id);
        if (
          this.productFilter.brand_id?.length ||
          this.productFilter.category_id?.length ||
          this.productFilter.sub_category_id?.length
        )
          this.refreshChildMenuData = true;
      }
    } else {
      this.subCategoryDropOpened = !this.subCategoryDropOpened;
      if (
        !this.subCategoryDropOpened &&
        this.productFilter.sub_category_id?.length
      ) {
        if (
          this.productFilter.brand_id?.length ||
          this.productFilter.category_id?.length ||
          this.productFilter.sub_category_id?.length
        )
          this.refreshChildMenuData = true;
      }
    }

    // if (value == 'category')
    //   this.categoryDropOpened = !this.categoryDropOpened;
    // else
    //   this.subCategoryDropOpened = !this.subCategoryDropOpened;
    // if (value == 'category' && !this.categoryDropOpened && this.productFilter.category_id && this.productFilter.category_id.length) {
    //   this.getSubCategoryDropList(this.productFilter.category_id)
    //   this.refreshChildMenuData = true;
    // }
    // if (!this.subCategoryDropOpened && this.productFilter.sub_category_id && this.productFilter.sub_category_id.length) {
    //   this.refreshChildMenuData = true;
    // }
  }

  selectionChangeOnProductFilter(type: string) {
    if (type === 'category')
      this.getSubCategoryDropList(this.productFilter.category_id);
    this.refreshChildMenuData = true;
  }

  openProductVariantForm(tempalte: any, productVariantId?: number) {
    this.selectedProductVariantId = productVariantId;
    this.dialog.closeAll();
    this.dialog.open(tempalte, {
      width: '60vw !important',
      height: '60vh',
      minWidth: '60vw',
    });
  }

  public updateList(event: any): void {
    if (event) {
      this.itemCountConfig = event;
    }
    this.refreshChildMenuData = false;
  }

  public searchDataTable(str: string): void {
    this.searchSub.next(str);
  }
}
