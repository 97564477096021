<ngx-spinner
  [fullScreen]="true"
  bdColor="#fff"
  size="medium"
  color="#050a4f"
  type="ball-clip-rotate-multiple"
>
</ngx-spinner>
<div class="container-fluid">
  <div class="row">
    <div class="col-md-4">
      <h2>Login Logs</h2>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-4"></div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <form
        class="m-0 col-sm-12 col-md-9"
        name="paymentFilterForm"
        [formGroup]="paymentFilterForm"
        (ngSubmit)="getLoginLogs()"
      >
        <div class="row justify-content-end">
          <div
            class="col-sm-12 col-md-3"
            [ngClass]="{ 'd-none': userRole != 1 }"
          >
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Store </mat-label>
              <mat-select
                formControlName="restaurant"
                (selectionChange)="getLoginLogs()"
              >
                <input
                  (keyup)="search($event.target.value)"
                  class="form-control w-100"
                  placeholder="Search Restaurant"
                />
                <mat-option value="0">ALL</mat-option>
                <mat-option
                  *ngFor="let rest of filteredRestaurantList"
                  [value]="rest.id"
                  >{{ rest.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>From</mat-label>
              <input
                (dateChange)="getLoginLogs()"
                readonly
                formControlName="from"
                matInput
                [matDatepicker]="picker"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-md-3">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>To</mat-label>
              <input
                (dateChange)="getLoginLogs()"
                readonly
                formControlName="to"
                matInput
                [matDatepicker]="to"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="to"
              ></mat-datepicker-toggle>
              <mat-datepicker #to></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="table-responsive">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z8 w-100"
    >
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno</th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ page?.pageIndex * page?.pageSize + i + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="activity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Activity</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.activity | titlecase }}
        </td>
      </ng-container>

      <ng-container matColumnDef="client">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Client</th>
        <td mat-cell *matCellDef="let element">
          {{
            returnJson(element?.client, "browser") +
              " " +
              returnJson(element?.client, "os")
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="user">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
        <td mat-cell *matCellDef="let element">{{ element?.user?.email }}</td>
      </ng-container>

      <ng-container matColumnDef="restaurant" *ngIf="userRole == 1">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Store</th>
        <td mat-cell *matCellDef="let element">
          <app-text-ellipsis
            [length]="20"
            [text]="element?.user?.restaurant?.name"
          ></app-text-ellipsis>
        </td>
      </ng-container>

      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created At</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.created_at | date : "dd-MM-yyyy hh:mm a" }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          {{
            language && language.hasOwnProperty("No data matching")
              ? language["No data matching"]
              : "No data matching"
          }}
        </td>
      </tr>
    </table>
  </div>

  <mat-paginator
    [length]="page.length"
    [pageIndex]="page.pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    (page)="customePaginator($event)"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
