import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { AppService } from 'src/app/app.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {

  user: any = {
    name: '',
    phone: '',
    email: '',
    is_active: true,
    created_at: '',
    role: '',
    state_id:'',
    city_id:'',
    password: '',
    picture: '',
    picture_name: '',
    id: null
  };
  userForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    role: new FormControl('', [Validators.required]),
    state_id: new FormControl('', [Validators.required]),
    city_id: new FormControl('', [Validators.required])
  });
  @ViewChild('file') file!: ElementRef;
  roleList: any = [];
  image = { name: '', src: '../../../../assets/images/placeholder.jpeg', file: '' }
  apiUrl: string = '';
  loggedUserRole: number;
  phoneobj: any = { number: '', countryCode: '' };
  SearchCountryField = SearchCountryField
  CountryISO = CountryISO
  PhoneNumberFormat = PhoneNumberFormat
  preferredCountries: CountryISO[] = [CountryISO.India]
  state: any;
  filteredCityList: any[];
  cityList: any;
  spinnerText: string = '';
  stateList: any;
  filteredStateList: any[];
  constructor(private userService: UserService, private toastr: ToastrService, private router: Router, private appService: AppService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.apiUrl = environment.API_URL;
    this.loggedUserRole = this.appService.getUserRole();
    this.getUser();
    this.setRoleList();
    this.getState();
  }

  ngAfterViewInit() {
    const node: any = document.querySelector('#phone');
    if (node) {
      node.style.border = 'unset';
      if (this.user && this.user.id) {
        node.readOnly = true;
      }
    }
  }

  setRoleList() {
    this.user.store_id = this.appService.getRestaurantId();
    if (this.loggedUserRole == 1) {
      this.roleList = [
        { id: 1, title: 'Super Admin' },
        { id: 9, title: 'State Admin' },
        { id: 10, title: 'City Admin' }
      ];
      this.user.role = 1;
    }
    else if (this.loggedUserRole == 2)
      this.roleList = [
        { id: 3, title: 'Manager' },
        { id: 4, title: 'Employee' }
      ]
    else
      this.roleList = [
        { id: 4, title: 'Employee' }
      ]
  }
getState(){
  this.spinnerText = 'Fetching State Data ...';
    this.spinner.show();
    this.userService.getStates().subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.stateList = res.data;
          this.filteredStateList = [...this.stateList];
        } else this.toastr.error(res.message, 'Error');
      },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    );
}
getCityList(state_id: number) {
  this.spinnerText = 'Fetching City Data ...';
  this.spinner.show();
  const data = { state_id: state_id };
  this.userService.getCities(data).subscribe(
    (res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.cityList = res.data;
        this.filteredCityList = [...this.cityList];
      } else this.toastr.error(res.message, 'Error');
    },
    (err: any) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error');
    }
  );
}
  getUser() {
    const user = this.userService.getUser();
    if (user && user.id) {
      this.user = user;
      this.user.role = parseInt(this.user.role);
      this.image.src = user.picture ? (this.apiUrl + '/' + user.picture) : this.image.src;
      const phoneobj = { number: this.user.phone, dialCode: this.user.dial_code, countryCode: this.user.country_code };
      this.phoneobj = phoneobj;
      this.getCityList(this.user.state_id)
    }
  }

  uploadPicture() {
    this.file.nativeElement.click();
  }

  readFile(event: any) {
    if (this.user.picture)
      this.user.picture = '';
    const file = event.target.files[0];
    this.image.name = file.name;
    this.image.file = file;

    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.image.src = reader.result as string;
      };
    }
  }

  onSubmit() {
    this.userForm.controls.phone.patchValue(this.phoneobj.number);
    if (this.userForm.invalid)
      return
    else {
      this.spinner.show();
      this.user.phone = this.phoneobj.number;
      this.user.country_code = this.phoneobj.countryCode;
      this.user.dial_code = this.phoneobj.dialCode;
      const formData = new FormData();
      formData.append('Image', this.image.file);
      formData.append('data', JSON.stringify(this.user));

      this.userService.createUpdateUser(formData).subscribe((res: any) => {
        this.userService.setUser(null);
        this.spinner.hide();
        if (res.status) {
          this.router.navigate(['/auth/users']);
          this.toastr.success(res.message, 'Success');
        }
        else
          this.toastr.error(res.message, 'Error')
      }, (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error')
      });
    }
  }
  search(value: string, type: string) {
    let filter = value;
    if (type == 'state') {
      this.filteredStateList = this.stateList.filter((state) => {
        let lowerCase = state.name.toLocaleLowerCase();
        return state.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    } else {
      this.filteredCityList = this.cityList.filter((city) => {
        let lowerCase = city.name.toLocaleLowerCase();
        return city.name.startsWith(filter) || lowerCase.startsWith(filter);
      });
    }
  }
}
