import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { OrderService } from '../order.service';
import { MatDialog } from '@angular/material/dialog';
import { OrderDetailComponent } from '../order-detail/order-detail.component';
import { AppService } from 'src/app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['sno', 'order_id', 'user_name', 'is_delivery', 'mobile', 'order_items', 'commission', 'tax', 'discount', 'order_total', 'created_at', 'status', 'action'];
  page = { length: 0, pageIndex: 0, pageSize: 10 };
  orderList: any = [];
  public orderFilters!: any[];
  spinnerText: string = '';
  userRole: number = 0;
  public selectedStatus: number = 1;
  public search!: Subject<boolean>;
  public searchText!: string;

  constructor(private orderService: OrderService, private router: Router, private dialog: MatDialog, private appService: AppService, private spinner: NgxSpinnerService, private toastr: ToastrService) { }

  ngOnInit() {
    // this.startSocket();
    this.userRole = this.appService.getUserRole();
    if (this.userRole != 4) {
      this.displayedColumns.push('status_action');
    }
    if (this.userRole === 1)
      this.displayedColumns.splice(2, 0, 'store');
    this.getOrderList(false);
    this.search = new Subject();
    this.search.pipe(debounceTime(500)).subscribe((res: boolean) => this.getOrderList(false, this.selectedStatus))
  }

  getOrderList(fromPagination: boolean, status?: number) {
    if (!isNaN(status))
      this.selectedStatus = status;
    if (this.userRole != 4 && (this.selectedStatus == 7 || this.selectedStatus == 5 || this.selectedStatus == 0)) {
      const removeStatActionInd = this.displayedColumns.findIndex(val => val === 'status_action');
      if (this.displayedColumns[removeStatActionInd])
        this.displayedColumns.splice(removeStatActionInd, 1);
    }
    else if (this.userRole != 4 && ((this.selectedStatus > 0 && this.selectedStatus < 5) || this.selectedStatus === 6)) {
      if (!this.displayedColumns.includes('status_action')) this.displayedColumns.push('status_action');
    }
    const data = {
      store_id: this.appService.getRestaurantId(),
      offset: this.page.pageIndex * this.page.pageSize,
      limit: this.page.pageSize,
      status: this.selectedStatus
    };
    if (this.searchText) {
      data['searchText'] = this.searchText;
    }
    this.spinnerText = 'Fetchnig orders';
    this.spinner.show();
    this.orderService.getOrderList(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.orderList = res?.data;
        this.orderFilters = res?.ordersByFilters;
        // if (this.userRole !== 1)
        //   this.orderList.map(order => order.status = order?.order_items[0].status);
        this.dataSource = new MatTableDataSource(this.orderList);
        this.page.length = res.ordersCount;
        this.dataSource.sort = this.sort;
        if (!fromPagination)
          this.dataSource.paginator = this.paginator;
      }
    }, (err: any) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error')
    })
  }

  customePaginator(event: any) {
    this.page.pageIndex = event.pageIndex;
    this.page.pageSize = event.pageSize;
    this.getOrderList(true);
  }

  canelOrder(element: any) {
    this.spinnerText = 'Cancelling Order';
    this.spinner.show();
    const data = { order_id: element.id };
    this.orderService.cancelOrder(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.toastr.success(res.message, 'Success');
        // const order: any = { order_status: 0, order_id: element.id, store_id: element.store_id, user_id: element.user_id };
        // this.orderService.createOrderWithSocket(order);
        this.getOrderList(false);
      }
      else
        this.toastr.error(res.message, 'Error')
    }, (err: any) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error')
    })
  }

  updateOrder(rowData: any) {
    let element: any = {};
    Object.assign(element, rowData);
    if (element.status == 1) {
      element.status = 2;
    }
    else if (element.status == 2)
      element.status = 3;
    else if (element.status == 3)
      element.status = 4;
    else if (element.status == 4)
      element.status = 5;
    this.updateOrderData(element);
  }

  updateOrderData(data: any) {
    this.spinnerText = 'Updating Order';
    this.spinner.show();
    this.orderService.updateOrder(data).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status) {
        this.toastr.success(res.message, 'Success');
        this.getOrderList(false);
      }
    }, (err: any) => {
      this.spinner.hide();
      this.toastr.error(err.message, 'Error')
    })
  }

  editOrder(order: any) {
    const dialogRef = this.dialog.open(OrderDetailComponent, {
      data: order,
      width: '80vw !important',
      height: '92vh', minWidth: '80vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.updateOrderList)
        this.getOrderList(false);
      else if (result)
        this.updateOrderData(result);
    });
  }

}
