<div class="h-100">
  <div class="row">
    <div class="col-md-5">
      <mat-form-field appearance="outline" class="w-100">
        <mat-label>Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event?.target?.value)"
          class="input-text"
          autocomplete="off"
        />
      </mat-form-field>
    </div>
    <div class="col-md-7 d-flex align-items-center justify-content-between">
      <mat-form-field appearance="outline" class="w-30">
        <mat-label>Brand </mat-label>
        <mat-select
          (openedChange)="openedChange('brand')"
          multiple
          [(ngModel)]="productFilter.brand_id"
        >
          <input
            (keyup)="search($event.target.value, 'brand')"
            class="form-control"
            placeholder="Search Brand"
          />
          <mat-option
            *ngFor="let brand of filteredBrandDropList"
            [value]="brand.id"
          >
            {{ brand.name }}
          </mat-option>
        </mat-select>
        <mat-error> Category is Required </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-30">
        <mat-label>Category </mat-label>
        <mat-select
          multiple
          (openedChange)="openedChange('category')"
          [(ngModel)]="productFilter.category_id"
        >
          <input
            (keyup)="search($event.target.value, 'category')"
            class="form-control"
            placeholder="Search Category"
          />
          <mat-option
            *ngFor="let category of filteredCategoryDropList"
            [value]="category.id"
          >
            {{ category.name }}
          </mat-option>
        </mat-select>
        <mat-error> Category is Required </mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline" class="w-30">
        <mat-label>Sub Category </mat-label>
        <mat-select
          multiple
          (openedChange)="openedChange('sub_category')"
          [(ngModel)]="productFilter.sub_category_id"
        >
          <input
            (keyup)="search($event.target.value, 'subcategory')"
            class="form-control"
            placeholder="Search Sub Category"
          />
          <mat-option
            *ngFor="let subCategory of filteredSubCategoryDropList"
            [value]="subCategory.id"
          >
            {{ subCategory.name }}</mat-option
          >
        </mat-select>
        <mat-error> Sub Category is Required </mat-error>
      </mat-form-field>
      <i
        [matTooltip]="'Store Product ' + productCount"
        class="fa fa-exclamation-circle text-secondary"
      ></i>
    </div>
  </div>

  <div class="h-80 table-responsive">
    <table
      mat-table
      [dataSource]="productDataSource"
      matSort
      class="mat-elevation-z8 w-100"
    >
      <ng-container
        matColumnDef="selected"
        *ngIf="appService?.getUserRole() != 1"
      >
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="selectAll($event)"
            [(ngModel)]="selectedAllProduct"
          ></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element" class="pr-3">
          <mat-checkbox
            (change)="updateAllCheckbox()"
            [(ngModel)]="element.selected"
          ></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno</th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ productPage.pageIndex * productPage.pageSize + i + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element">{{ element?.name }}</td>
      </ng-container>

      <ng-container matColumnDef="brand">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Brand</th>
        <td mat-cell *matCellDef="let element">{{ element?.brand?.name }}</td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.category?.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="sub_category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub Category</th>
        <td mat-cell *matCellDef="let element">
          {{ element?.sub_category?.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="picture">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Picture</th>
        <td mat-cell *matCellDef="let element">
          <img
            [src]="apiUrl + '/' + element?.picture"
            [alt]="element?.picture"
            class="w-50px"
            *ngIf="element?.picture"
          />
        </td>
      </ng-container>

      <ng-container matColumnDef="alert">
        <th mat-header-cell *matHeaderCellDef class="text-center"></th>
        <td mat-cell *matCellDef="let element" class="text-center">
          <i
            matTooltip="Choose or Add variant"
            class="fa fa-exclamation-circle text-danger"
            *ngIf="!element?.hasItemVariant && element?.itemSynced"
          ></i>
        </td>
      </ng-container>

      <ng-container matColumnDef="variant">
        <th mat-header-cell *matHeaderCellDef class="text-center">Variants</th>
        <td mat-cell *matCellDef="let element; index as i" class="text-center">
          <button
            *ngIf="element?.itemSynced"
            class="btn btn-info ml-3"
            (click)="openVariantList.emit(element)"
          >
            <i class="fa fa-history text-white" aria-hidden="true"></i>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="productColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: productColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          {{
            language && language.hasOwnProperty("No data matching")
              ? language["No data matching"]
              : "No data matching"
          }}
        </td>
      </tr>
    </table>
  </div>

  <div class="d-flex justify-content-end mt-4 w-100">
    <button class="btn btn-success" (click)="updateGlobalList()">Update</button>
  </div>
</div>
