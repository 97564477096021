<div class="container-fluid bg-white">
  <form name="helpForm" [formGroup]="helpForm" (ngSubmit)="onSubmit()">
    <h2 class="mb-3">Ask Help</h2>
    <div class="row">
      <div class="col-sm-12 col-md-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Type of Issue </mat-label>
          <mat-select
            [(ngModel)]="help.issue"
            required
            formControlName="issue"
            (selectionChange)="updateValidation()"
          >
            <mat-option value="">Select Issue</mat-option>
            <mat-option *ngFor="let issue of issueList" [value]="issue.id"
              >{{ issue.value }}
            </mat-option>
          </mat-select>
          <mat-error> Issue Type is Required </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-8">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Description </mat-label>
          <textarea
            matInput
            [(ngModel)]="help.desc"
            minlength="1"
            maxlength="500"
            formControlName="desc"
          ></textarea>
          <mat-error> Description is Required </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-4">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Prority </mat-label>
          <mat-select
            [(ngModel)]="help.priority"
            required
            formControlName="priority"
          >
            <mat-option value="">Select Prority</mat-option>
            <mat-option
              *ngFor="let priority of priorityList"
              [value]="priority.id"
              >{{ priority.value }}
            </mat-option>
          </mat-select>
          <mat-error> Priority is Required </mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-4" *ngIf="help && help.picture">
        <img [src]="help.picture" [alt]="help.picture_name" class="w-50 mr-3" />
        <p class="mt-3">{{ help.picture_name }}</p>
      </div>
      <div class="col-sm-12 col-md-4">
        <button mat-flat-button type="button" (click)="uploadPicture()">
          Upload Picture
        </button>
        <button
          mat-flat-button
          type="button"
          (click)="closeHelp()"
          *ngIf="helpId"
          class="bg-success text-white ml-2"
        >
          Mark As Closed
        </button>
        <input
          type="file"
          #file
          accept="image/png, image/gif, image/jpeg"
          class="d-none"
          (change)="readFile($event)"
          multiple
        />
      </div>
    </div>
    <div class="row justify-content-end">
      <div
        class="col-sm-12 col-md-4 d-flex align-items-center justify-content-end"
      >
        <button
          mat-flat-button
          type="submit"
          class="bg-success text-white w-50"
        >
          Submit
        </button>
      </div>
    </div>
  </form>
</div>
