<div class="container-fluid">
  <div class="row">
    <div class="col-md-4">
      <h2>Promotion List</h2>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-4 m-2">
      <a
        class="bg-success text-white"
        mat-flat-button
        routerLink="/admin/promotion-form"
        >Create Promotion
      </a>
    </div>
  </div>

  <div class="table-responsive">
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      class="mat-elevation-z8 w-100"
    >
      <ng-container matColumnDef="sno">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sno</th>
        <td mat-cell *matCellDef="let element; let i = index">
          {{ page.pageIndex * page.pageSize + i + 1 }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="desc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let element">{{ element.desc }}</td>
      </ng-container>

      <ng-container matColumnDef="start_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.start_date | date : "dd-MM-yyyy" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="end_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.end_date | date : "dd-MM-yyyy" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="order_amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Amount</th>
        <td mat-cell *matCellDef="let element">{{ element.order_amount }}</td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
        <td mat-cell *matCellDef="let element">{{ element.amount }}</td>
      </ng-container>

      <ng-container matColumnDef="percentage">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Percentage</th>
        <td mat-cell *matCellDef="let element">{{ element.percentage }}</td>
      </ng-container>

      <ng-container matColumnDef="created_by">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
        <td mat-cell *matCellDef="let element">
          {{ element.store_id ? "Store" : "Order2Pay Super Admin" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
        <td mat-cell *matCellDef="let element; index as i" class="text-center">
          <button
            mat-flat-button
            (click)="editPromotion(element.id)"
            [disabled]="!element.store_id && user.role != 1"
          >
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.75 9.06298V11.2505H2.9375L9.38917 4.79881L7.20167 2.61131L0.75 9.06298ZM11.0808 3.10715C11.3083 2.87965 11.3083 2.51215 11.0808 2.28465L9.71583 0.919648C9.48833 0.692148 9.12083 0.692148 8.89333 0.919648L7.82583 1.98715L10.0133 4.17465L11.0808 3.10715Z"
                fill="white"
              />
            </svg>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td sty class="mat-cell text-center" colspan="12">
          {{
            language && language.hasOwnProperty("No data matching")
              ? language["No data matching"]
              : "No data matching"
          }}
        </td>
      </tr>
    </table>
  </div>

  <mat-paginator
    [length]="page.length"
    [pageIndex]="page.pageIndex"
    [pageSizeOptions]="pageSizeOptions"
    (page)="customePaginator($event)"
    showFirstLastButtons
  >
  </mat-paginator>
</div>
