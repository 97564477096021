import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTimepickerModule } from 'mat-timepicker';
import { MatMenuModule } from '@angular/material/menu';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatTreeModule } from '@angular/material/tree';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ConfirmationComponent } from './confirmation-dialog/confirmation-dialog.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [ConfirmationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MatDialogModule,
    MatCheckboxModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatRadioModule,
    MatTabsModule,
    MatCardModule,
    MatButtonToggleModule,
    MatTimepickerModule,
    MatMenuModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    MatSlideToggleModule,
    CdkTreeModule,
    MatTreeModule,
    MatExpansionModule,
    NgxSpinnerModule,
    MatRippleModule,
    NgApexchartsModule,
    NgxIntlTelInputModule,
    MatTooltipModule
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDatepickerModule,
    MatDialogModule,
    MatCheckboxModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatRadioModule,
    MatTabsModule,
    MatCardModule,
    MatButtonToggleModule,
    MatTimepickerModule,
    MatMenuModule,
    ToastrModule,
    HttpClientModule,
    MatSlideToggleModule,
    CdkTreeModule,
    MatTreeModule,
    MatExpansionModule,
    NgxSpinnerModule,
    MatRippleModule,
    NgApexchartsModule,
    ConfirmationComponent,
    NgxIntlTelInputModule,
    MatTooltipModule
  ]
})
export class SharedModule { }
