import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppService } from 'src/app/app.service';
import { UserService } from '../user/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RestaurantService } from 'src/app/admin/restaurant/restaurant.service';
import { MatDialog } from '@angular/material/dialog';
// declare var Ippopay: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public showPassword: boolean = false;

  @ViewChild('callAPIDialog') callAPIDialog: TemplateRef<any>;
  @ViewChild('callAPIDialog1') callAPIDialog1: TemplateRef<any>;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', Validators.required),
  });
  newMessage: string;
  messageList: string[] = [];
  options:
    | any
    | { hour12: boolean; hour: string; minute: string; second: string };
  curDate: any;
  endDate: any;
  endDate1: any;
  planDetails: any = [];
  lapshow: boolean;
  mobshow: boolean;

  constructor(
    private router: Router,
    private userService: UserService,
    private appService: AppService,
    public toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private deviceService: DeviceDetectorService,
    private api: RestaurantService,
    public dialog: MatDialog
  ) {
    // Ippopay.init();
  }

  ngOnInit(): void {
    var w = window.innerWidth;
    var h = window.innerHeight;
    if (w >= 900) {
      console.log('tablapwidth', w, 'height', h);
      this.lapshow = true;
      this.mobshow = false;
    } else {
      console.log('mobwidth', w, 'height', h);
      this.mobshow = true;
      this.lapshow = false;
    }
    var today = new Date(localStorage.getItem('current_date'));
    var year = today.getFullYear();
    var mes = today.getMonth() + 1;
    var dia = today.getDate();
    this.curDate = year + '-' + mes + '-' + dia;
    // this.curDate = localStorage.getItem('current_date');

    const token = this.appService.getToken();
    if (token) {
      const user = this.appService.getUserDetails();
      var today1 = new Date(user?.store?.store_plan?.end_date);
      var year1 = today1.getFullYear();
      var mes1 = today1.getMonth() + 1;
      var dia1 = today1.getDate();
      this.endDate1 = year1 + '-' + mes1 + '-' + dia1;
      var ed1 = new Date(this.endDate1);
      var cd1 = new Date(this.curDate);

      // if (
      //   user?.role == 1 ||
      //   (user?.role == 2 && this.endDate1 >= this.curDate)
      // ) {
      //   this.router.navigate(['/admin/dashboard']);
      // } else if (
      //   user?.role != 1 ||
      //   (user?.role != 2 && this.endDate1 >= this.curDate)
      // ) {
      //   this.router.navigate(['/order/list']);
      // }

      if (user?.role == 1 || user?.role == 6 || user?.role == 7) {
        this.router.navigate(['/admin/dashboard']);
      } else if (user?.role == 2 && ed1 >= cd1) {
        this.router.navigate(['/admin/dashboard']);
      } else if (user?.role == 3 || user?.role == 4) {
        this.router.navigate(['/order/list']);
      } 
      else if (user?.role == 8) {
        this.router.navigate(['/order/del-orders']); 
      }

      else {
        this.toastr.warning('Please try to enter your Login credentials...');
      }
    }

    // console.log(this.curDate);
    //ippo pay
    // const ippopayHandler = (e: any) => {
    //   if (e.data.status == 'success') {
    //     console.log(e.data);
    //   }
    //   if (e.data.status == 'failure') {
    //     console.log(e.data);
    //   }
    // };
    // window.addEventListener('message', ippopayHandler);
    // Ippopay.open('123', 'pk_test_Ug41CaEgvvys');
  }

  public purchasePlan(item: any, type: number): void {
    const data = {
      store_id: localStorage.getItem('store_id'),
      plan_id: item?.id,
      type,
    };
    this.spinner.show();
    this.api.createStorePlan(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res?.status) {
          this.dialog.closeAll();
          this.toastr.success(res?.message, 'Success');
        } else {
          this.toastr.error(res?.message, 'Error');
        }
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err?.message, 'Error');
      }
    );
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  saveLoginLog() {
    const data = {
      client: JSON.stringify(this.deviceService.getDeviceInfo()),
      activity: 'login',
    };
    this.spinner.show();
    this.userService.createLoginLog(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (
          this.appService.getUserRole() == 1 ||
          this.appService.getUserRole() == 2 ||
          this.appService.getUserRole() == 6 ||
          this.appService.getUserRole() == 7
        ) {
          this.router.navigate(['/admin/dashboard']);
          window.location.reload();
        } 
        else if (this.appService.getUserRole() == 8) {
          this.router.navigate(['/order/del-orders']); 
          window.location.reload();
        }
        else {
          this.router.navigate(['/order/list']);
          window.location.reload();
        }
      },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    );
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit(form, event) {
    if (this.loginForm.invalid) {
      return;
    } else {
      this.spinner.show();
      const data = form.value;
      data['admin'] = true;
      this.userService.login(data).subscribe(
        (res: any) => {
          this.spinner.hide();

          // console.log(res.user.role);
          // console.log(res.status);
          // console.log(res.user);
          if (res.status && res.user) {
            // console.log(res.user.store.store_plan.end_date);
            var today = new Date(res?.user?.store?.store_plan?.end_date);
            var year = today.getFullYear();
            var mes = today.getMonth() + 1;
            var dia = today.getDate();
            this.endDate = year + '-' + mes + '-' + dia;
            var ed = new Date(this.endDate);
            var cd = new Date(this.curDate);
            // console.log(ed, cd);
            // console.log('oo', ed >= cd);
            // console.log('ll', ed <= cd);
            // console.log(this.endDate, this.curDate);
            // console.log(this.endDate >= this.curDate);
            // console.log(this.endDate <= this.curDate);

            if (
              res.user.role == 1 ||
              res.user.role == 6 ||
              res.user.role == 7 ||
              res.user.role == 8
            ) {
              this.appService.setUserDetails(res);
              this.saveLoginLog();
            } else if (ed >= cd) {
              this.appService.setUserDetails(res);
              this.saveLoginLog();
            } else if (ed <= cd) {
              this.appService.setUserDetails(res);
              this.toastr.error(
                'Your Store Plan is Expired......Please Renew Your Plan',
                'Error'
              );

              const data = {};
              this.api.getPlans(data).subscribe((res: any) => {
                this.planDetails = res.data;
                console.log('Plan', this.planDetails);

                const dialogRef = this.dialog.open(this.callAPIDialog, {
                  // width: '60vw !important',
                  // height: '60vh',
                  // minWidth: '60vw',
                });

                // this.dialog.open(this.productVariants, {
                //   width: '60vw !important',
                //   height: '60vh',
                //   minWidth: '60vw',
                // });

                dialogRef.afterClosed().subscribe((result) => {
                  console.log(`Dialog result: ${result}`);
                });
              });
            }
          } else this.toastr.error(res.message, 'Error');
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err.message, 'Error');
        }
      );
    }
  }

  termsAndConditions() {
    const dialogRef = this.dialog.open(this.callAPIDialog1, {
      // width: '60vw !important',
      // height: '60vh',
      // minWidth: '60vw',
    });

    // this.dialog.open(this.productVariants, {
    //   width: '60vw !important',
    //   height: '60vh',
    //   minWidth: '60vw',
    // });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}

@Component({
  selector: 'app-plan-dialog',
  templateUrl: '../plan-dialog/plan-dialog.component.html',
  styleUrls: ['../plan-dialog/plan-dialog.component.scss'],
})
export class PlanDialogComponent {}
