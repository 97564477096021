import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { PageEvent } from '@angular/material/paginator';
import { RestaurantService } from '../restaurant.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { BrandService } from '../../product/brand.service';
import { CategoryService } from '../../product/category.service';
import { SubcategoryService } from '../../product/subcategory.service';

@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.scss'],
})
export class RestaurantComponent implements OnInit {
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  dataSource: any = new MatTableDataSource();
  displayedColumns: string[] = [
    'sno',
    'name',
    'plan',
    'city',
    'state',
    'phone',
    'email',
    'status',
    'picture',
    'action',
  ];
  restaurantList = [];
  pageSizeOptions = [10, 15, 20];
  pageEvent: PageEvent;
  page = { length: 0, pageIndex: 0, pageSize: 10 };
  apiUrl: string = '';
  spinnerText: string = '';
  weekList: any = [
    {
      day: 1,
      title: 'Monday',
      discount: 0,
      commission: 0,
      min_order_amount: 0,
      store_id: 0,
    },
    {
      day: 2,
      title: 'Tuesday',
      discount: 0,
      commission: 0,
      min_order_amount: 0,
      store_id: 0,
    },
    {
      day: 3,
      title: 'Wednesday',
      discount: 0,
      commission: 0,
      min_order_amount: 0,
      store_id: 0,
    },
    {
      day: 4,
      title: 'Thursday',
      discount: 0,
      commission: 0,
      min_order_amount: 0,
      store_id: 0,
    },
    {
      day: 5,
      title: 'Friday',
      discount: 0,
      commission: 0,
      min_order_amount: 0,
      store_id: 0,
    },
    {
      day: 6,
      title: 'Saturday',
      discount: 0,
      commission: 0,
      min_order_amount: 0,
      store_id: 0,
    },
    {
      day: 7,
      title: 'Sunday',
      discount: 0,
      commission: 0,
      min_order_amount: 0,
      store_id: 0,
    },
  ];
  @ViewChild('commissionModal') private commissionModal!: TemplateRef<any>;
  @ViewChild('menuList') private menuList!: TemplateRef<any>;
  @ViewChild('planList') private planList!: TemplateRef<any>;
  public getPlan: boolean = false;

  selectedMenuRestaurantId: number;
  selectedMenuPriceId: number;
  selectedProduct;
  userRole: number;
  productFilter = {
    category_id: null,
    sub_category_id: null,
    brand_id: null,
  };
  public brandDropOpened: boolean = false;
  public categoryDropOpened: boolean = false;
  public subCategoryDropOpened: boolean = false;
  public refreshChildMenuData: boolean = false;
  public filteredCategoryDropList: any = [];
  private categoryDropList: any = [];
  public filteredSubCategoryDropList: any = [];
  private subCategoryDropList: any = [];
  public filteredBrandDropList: any = [];
  private brandDropList: any = [];
  public searchString!: string;
  public selectedTabIndex: number = 0;
  public planStoreId!: number;
  public filteredStoreList!: any;

  constructor(
    private restaurantService: RestaurantService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private appService: AppService,
    private brandService: BrandService,
    private categoryService: CategoryService,
    private subcategoryService: SubcategoryService
  ) {}

  ngOnInit(): void {
    this.userRole = this.appService.getUserRole();
    this.getRestaurantList();
    this.apiUrl = environment.API_URL;
    this.getBrandList();
    this.getCategoryDropList();
  }

  getRestaurantList() {
    this.spinnerText = 'Fetching Store Data';
    this.spinner.show();
    this.restaurantService.findAllRestaurants({}).subscribe(
      (res: any) => {
        if (res.status) {
          console.log(res);

          this.restaurantList = res?.data;
          this.filteredStoreList = [...res?.data];
          this.applyDataTable();
        } else this.toastr.error(res.message, 'Error');
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    );
  }

  editRestaurant(element: any) {
    this.router.navigate(['/admin/restaurant-form'], {
      queryParams: { id: element.id },
    });
  }

  customePaginator(event) {
    this.page.pageIndex = event.pageIndex;
    this.page.pageSize = event.pageSize;
  }

  openCommission(data: any) {
    for (let day of this.weekList) day.store_id = data.id;
    if (data?.store_commission?.length) {
      for (let commission of data.store_commission) {
        let day = this.weekList.find((day: any) => day.day == commission.day);
        commission.title = day.title;
      }
      this.weekList = data.store_commission;
    }
    this.dialog.open(this.commissionModal, {
      width: '60vw !important',
      height: '88vh',
      minWidth: '60vw',
    });
  }

  openMenuList(element: any) {
    this.selectedMenuRestaurantId = element.id;
    this.dialog.open(this.menuList, {
      width: '95vw !important',
      height: '95vh',
      minWidth: '95vw',
    });
  }

  updateSettings() {
    let row = 1;
    for (let day of this.weekList) {
      if (day.commission == null) {
        this.toastr.warning(
          `Commission should not be empty on row ${row}`,
          'Warning'
        );
        return;
      } else if (day.discount && day.min_order_amount == null) {
        this.toastr.warning(
          `Minimum order amount should not be empty on row ${row}`,
          'Warning'
        );
        return;
      } else if (day.discount && day.min_order_amount <= 0) {
        this.toastr.warning(
          `Minimum order amount should be greater than 0 on row ${row}`,
          'Warning'
        );
        return;
      } else if (day.discount > day.commission) {
        this.toastr.warning(
          `Discount should not be greater than the commission on row ${row}`,
          'Warning'
        );
        return;
      }
      row++;
    }
    this.spinnerText = 'Updating Restaurant Settings';
    this.spinner.show();
    this.restaurantService.updateSettings(this.weekList).subscribe(
      (res: any) => {
        this.weekList = [
          {
            day: 1,
            title: 'MonDay',
            discount: 0,
            commission: 1,
            min_order_amount: 0,
            store_id: 0,
          },
          {
            day: 2,
            title: 'TuesDay',
            discount: 0,
            commission: 1,
            min_order_amount: 0,
            store_id: 0,
          },
          {
            day: 3,
            title: 'WednesDay',
            discount: 0,
            commission: 1,
            min_order_amount: 0,
            store_id: 0,
          },
          {
            day: 4,
            title: 'ThursDay',
            discount: 0,
            commission: 1,
            min_order_amount: 0,
            store_id: 0,
          },
          {
            day: 5,
            title: 'FriDay',
            discount: 0,
            commission: 1,
            min_order_amount: 0,
            store_id: 0,
          },
          {
            day: 6,
            title: 'SaturDay',
            discount: 0,
            commission: 1,
            min_order_amount: 0,
            store_id: 0,
          },
          {
            day: 7,
            title: 'SunDay',
            discount: 0,
            commission: 1,
            min_order_amount: 0,
            store_id: 0,
          },
        ];
        this.getRestaurantList();
        this.dialog.closeAll();
        if (res.status) {
          this.toastr.success(res.message, 'Success');
        }
        this.spinner.hide();
      },
      (err: any) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  openedChange(value) {
    if (value == 'brand') {
      this.brandDropOpened = !this.brandDropOpened;
      if (
        !this.brandDropOpened &&
        (this.productFilter.brand_id?.length ||
          this.productFilter.category_id?.length ||
          this.productFilter.sub_category_id?.length)
      )
        this.refreshChildMenuData = true;
    } else if (value == 'category') {
      this.categoryDropOpened = !this.categoryDropOpened;
      if (!this.categoryDropOpened && this.productFilter.category_id?.length) {
        this.getSubCategoryDropList();
        if (
          this.productFilter.brand_id?.length ||
          this.productFilter.category_id?.length ||
          this.productFilter.sub_category_id?.length
        )
          this.refreshChildMenuData = true;
      }
    } else {
      this.subCategoryDropOpened = !this.subCategoryDropOpened;
      if (
        !this.subCategoryDropOpened &&
        this.productFilter.sub_category_id?.length &&
        (this.productFilter.brand_id?.length ||
          this.productFilter.category_id?.length ||
          this.productFilter.sub_category_id?.length)
      )
        this.refreshChildMenuData = true;
    }
  }

  getSubCategoryDropList() {
    const data = {
      dropDown: true,
      store_id: this.selectedMenuRestaurantId,
      category_id: this.productFilter?.category_id,
    };
    this.spinner.show();
    this.subcategoryService.findAllSubCatgegories(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.subCategoryDropList = res.data;
          this.filteredSubCategoryDropList = [...this.subCategoryDropList];
        } else this.toastr.error(res.message, 'Error');
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    );
  }

  getCategoryDropList() {
    const data = { dropDown: true };
    this.spinner.show();
    this.categoryService.findAllCatgegories(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.categoryDropList = res.data;
          this.filteredCategoryDropList = [...this.categoryDropList];
        } else this.toastr.error(res.message, 'Error');
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    );
  }

  getBrandList() {
    const data = {};
    this.spinner.show();
    this.brandService.findAllBrand(data).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (res.status) {
          this.brandDropList = res.data;
          this.filteredBrandDropList = [...this.brandDropList];
        } else this.toastr.error(res.message, 'Error');
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err.message, 'Error');
      }
    );
  }

  public changeTab(event: any): void {
    if (event?.index === 2) {
      this.getPlan = true;
    } else {
      this.selectedTabIndex = event?.index;
      this.applyDataTable();
      this.getPlan = false;
    }
  }

  private applyDataTable(): void {
    const list = [
      ...this.restaurantList.filter((res) =>
        this.selectedTabIndex === 1
          ? res?.user?.role === 5 && !res?.is_active
          : res?.user?.role === 1 ||
            res?.user?.role === 9 ||
            res?.user?.role === 10
      ),
    ];
    this.dataSource = new MatTableDataSource(list);
    this.page.length = list.length;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  public search(value: string) {
    this.filteredStoreList = this.restaurantList.filter((store) => {
      let lowerCase = store.name.toLocaleLowerCase();
      return store?.name?.startsWith(value) || lowerCase.startsWith(value);
    });
  }
}
